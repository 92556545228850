import React, { Fragment, useState, useEffect, useCallback } from "react"
import { withRouter } from "react-router-dom"
import axios from 'axios'
import moment from 'moment'
import DataCard from "./data-card"
import PieTwinChart from "./pie-twin-chart"
import InputDateRange from "../../components/input-date-range"
import CONS from "../../constants"
import Format from "../../functions/format"
import Log from "../../functions/log"

function TargetComponent(props) {
    const [period, setPeriod] = useState({
        startDate: moment().subtract(29, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
    })
    const [userActive, setUserActive] = useState({
        featureActiveUser: 0,
        registerUser: 0,
        loading: true
    })
    const [transactionCount, setTransactionCount] = useState({
        amount: 0,
        loading: true
    })
    const [calculateStatus, setCalculateStatus] = useState({
        label: ["ภาษี", "เงินออมเกษียณ", "ผ่อนรถ"],
        count: [0, 0, 0],
    })
    const [calculateTop, setCalculateTop] = useState({
        loading: true,
        list: []
    })

    const loadUserActive = useCallback(() => {
        Log.save(CONS.API_USER_ACTIVE_FEATURE + `?feature=4&start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {})
        axios.get(CONS.API_USER_ACTIVE_FEATURE + `?feature=4&start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                setUserActive({
                    featureActiveUser: response.data.featureActiveUser,
                    registerUser: response.data.registerUser,
                    loading: false
                })
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }, [props.history, period])

    const loadTransactionCount = useCallback(() => {
        Log.save(CONS.API_TRAMSACTION_COUNT_FEATURE + `?feature=4&start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {})
        axios.get(CONS.API_TRAMSACTION_COUNT_FEATURE + `?feature=4&start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                setTransactionCount({
                    amount: response.data.amount,
                    loading: false
                })
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }, [props.history, period])

    const loadCalculate = useCallback(() => {
        Log.save(CONS.API_CALCULATE + `?start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {})
        axios.get(CONS.API_CALCULATE + `?start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                let detail = [
                    { title: "ภาษี", code: "TAX", },
                    { title: "ภาษี", code: "TEX", },
                    { title: "เงินออมเกษียณ", code: "RTS" },
                    { title: "ผ่อนรถ", code: "LES" },
                    { title: "ผ่อนบ้าน", code: "MOR" },
                    { title: "เงินออมในกองทุนสำรองเลี้ยงชีพ", code: "PVD" },
                    { title: "มุลค่าเงินตามเวลา", code: "TVM" },
                    { title: "เงินลงทุนหลังเกษียณ", code: "INR" },
                    { title: "แบบทดสอบสุขภาพทางการเงิน", code: "HEL" },
                    { title: "แบบทดสอบระดับความเสี่ยง", code: "RIS" },
                ]
                let newData = {
                    list: [],
                    loading: false
                }
                let newData2 = {
                    label: [],
                    count: [],
                }
                response.data.data.map((item) => {
                    let dataDetail = detail.find(x => x.code === item.name)
                    if (dataDetail) {
                        let idx = newData.list.findIndex(x => x.title === dataDetail.title)
                        if (idx === -1) {
                            newData.list.push({
                                title: dataDetail.title, total: item.count, type: "", unit: "ครั้ง"
                            })
                        } else {
                            newData.list[idx].total += item.count
                        }
                    }
                    // if (item.name === "TAX") {
                    //     newData2.count[0] = item.count
                    // }
                    // if (item.name === "RTS") {
                    //     newData2.count[1] = item.count
                    // }
                    // if (item.name === "LES") {
                    //     newData2.count[2] = item.count
                    // }
                    return true
                })

                newData.list.sort((a, b) => a.total + b.total)

                newData.list.map((item) => {
                    newData2.label.push(item.title)
                    newData2.count.push(item.total)
                    return true
                })

                setCalculateTop({ ...newData })
                setCalculateStatus({ ...newData2 })
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }, [props.history, period])

    useEffect(() => {
        loadUserActive()
    }, [loadUserActive])

    useEffect(() => {
        loadTransactionCount()
    }, [loadTransactionCount])

    useEffect(() => {
        loadCalculate()
    }, [loadCalculate])

    return (
        <Fragment >
            <div className="row g-3 justify-content-center mb-3">
                <div className="col-12 col-md-5 col-xl-3">
                    <InputDateRange period={period} onChange={(startDate, endDate) => setPeriod({ startDate: startDate, endDate: endDate })} />
                </div>
            </div>
            <div className="row g-3 mb-3">
                <DataCard parentClassName="col-12 col-md-6 col-lg-3" title="จำนวนบัญชีที่มีการใช้งานแอป (Active user)" data={userActive.loading ? "....." : Format.price(userActive.registerUser)} unit="บัญชี" />
                <DataCard parentClassName="col-12 col-md-6 col-lg-3" title="บัญชีที่ใช้ฟีเจอร์นี้ (Feature active user)" data={userActive.loading ? "....." : Format.price(userActive.featureActiveUser)} unit="FAU" />
                <DataCard parentClassName="col-12 col-md-6 col-lg-3" title="บันทึกรายการ (Feature transactions)" data={transactionCount.loading ? "....." : Format.price(transactionCount.amount)} unit="รายการ" />
                <DataCard parentClassName="col-12 col-md-6 col-lg-3" title="ค่าเฉลี่ยการใช้งานฟีเจอร์ ต่อบัญชี" data={transactionCount.loading || userActive.loading ? "....." : Format.price((transactionCount.amount / userActive.featureActiveUser), 2)} unit="รายการ/FAU" />
            </div>
            <div className="row g-3 mb-3">
                <PieTwinChart
                    parentClassName="col-12 col-lg-6"
                    title="โปรแกรมคำนวณ"
                    backgroundColor={["rgba(255, 193, 7)", "rgba(48, 165, 24)", "rgba(255, 0, 0)", "rgb(209 7 255)", "rgb(98 202 255)", "rgb(255 204 250)", "rgb(147 147 147)", "rgb(41 44 239)", "rgb(255 151 56)"]}
                    labels={calculateStatus.label}
                    data={calculateStatus.count}
                    hideType={true}
                    hideList={true}
                    unit="ครั้ง"
                />
                <PieTwinChart
                    parentClassName="col-12 col-lg-6"
                    titleList="อันดับการใช้งานโปรแกรมคำนวณ"
                    lists={calculateTop.list}
                    loading={calculateTop.loading}
                    hideType={true}
                    hideChart={true}
                />
            </div>
        </Fragment>
    )
}

export default withRouter(TargetComponent)