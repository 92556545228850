var Dataset = {
    last12Month(type = "text") {
        const monthList = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."]
        let month = (new Date()).getMonth()
        let year = (new Date()).getFullYear() + 543
        let list = []
        for (var i = 1; i <= 12; i++) {
            if (month === -1) {
                month = 11
                year = year - 1
            }
            list.unshift((type === "text" ? monthList[month] + year.toString().substring(2, 4) : (year - 543) + "-" + (month < 9 ? "0" + (month + 1) : month + 1)))
            month--
        }
        return list
    },

    name12Month(year = (new Date()).getFullYear(), type = "text") {
        const monthList = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."]
        let list = []
        let i = 1
        for (const item of monthList) {
            list.push((type === "text" ? item : (year + "-" + (i < 10 ? "0" + (i) : i))))
            i++
        }
        return list
    },

    yearList(endYear = ((new Date()).getFullYear() - 5), startYear = (new Date()).getFullYear()) {
        var yearList = []
        for (var i = startYear; i >= endYear; i--) {
            yearList.push({
                label: i,
                value: i
            })
        }
        return yearList
    },

    arrayMonth() {
        return [
            { value: "01", label: "มกราคม" },
            { value: "02", label: "กุมภาพันธ์" },
            { value: "03", label: "มีนาคม" },
            { value: "04", label: "เมษายน" },
            { value: "05", label: "พฤษภาคม" },
            { value: "06", label: "มิถุนายน" },
            { value: "07", label: "กรกฎาคม" },
            { value: "08", label: "สิงหาคม" },
            { value: "09", label: "กันยายน" },
            { value: "10", label: "ตุลาคม" },
            { value: "11", label: "พฤศจิกายน" },
            { value: "12", label: "ธันวาคม" }
        ]
    }
}

module.exports = Dataset