import React, { useState, useEffect, useCallback } from "react"
import { withRouter, Link } from "react-router-dom"
import axios from 'axios'
import MainLayout from "../../components/main-layout"
import Title from "../../components/title"
import Alert from "../../functions/alert"
import Valid from '../../functions/validation'
import CONS from "../../constants"
import PERMISSION from "../../constants/permisstion"
import Log from "../../functions/log"

function AdminEntryApp(props) {
    const [id] = useState((props.match.params.id) ? props.match.params.id : "create")
    const [loading, setLoading] = useState((id === "create") ? false : true)
    const [show, setShow] = useState(false)
    const [data, setData] = useState({
        username: "",
        password: "",
        confirm_password: "",
        name: "",
        email: "",
        role: []
    })

    const handleChange = (e) => {
        data[e.target.name] = (e.target.validity === undefined || !e.target.validity.patternMismatch ? e.target.value : data[e.target.name])
        setData({ ...data })
    }

    const handleChangePermission = (e) => {
        let newData = { ...data }
        if (e.target.checked) {
            newData.role.push(e.target.value)
        } else {
            newData.role.splice(newData.role.findIndex(x => x === e.target.value), 1)
        }
        setData({ ...data })
    }

    const handleSave = () => {
        var valid = Valid.form("admin-form")
        if (valid) {
            if (id === "create") {
                let re = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                if (!re.test(data.email)) {
                    Alert.showMsg("รูปแบบอีเมล์ไม่ถูกต้อง", "danger")
                    return false
                }

                if (data.password.length < 8) {
                    Alert.showMsg("รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร", "danger")
                    return false
                }

                if (data.password.length > 15) {
                    Alert.showMsg("รหัสผ่านต้องมีไม่เกิน 15 ตัวอักษร", "danger")
                    return false
                }

                if (!(new RegExp("[a-z]").test(data.password)) || !(new RegExp("[A-Z]").test(data.password))) {
                    Alert.showMsg("รหัสผ่านต้องประกอบด้วย ตัวอักษรภาษาอังกฤษพิมพ์เล็กและตัวพิมพ์ใหญ่", "danger")
                    return false
                }

                if (!(new RegExp("[0-9]").test(data.password))) {
                    Alert.showMsg("รหัสผ่านต้องมีตัวเลขอารบิก", "danger")
                    return false
                }

                if (data.password !== data.confirm_password) {
                    Alert.showMsg("รหัสผ่านไม่ตรงกัน", "danger")
                    return false
                }
                Alert.loadingScreen()
                Log.save(CONS.API_ADMIN + `/user`, { ...data })
                axios.put(CONS.API_ADMIN + "/user", { ...data }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
                    }
                }).then((response) => {
                    Alert.loadingScreen()
                    if (response.data.status === 200) {
                        Alert.showMsg("บันทึกข้อมูลสำเร็จ")
                        props.history.push(CONS.URL_LINK + "/admin")
                    } else {
                        Alert.showMsg(response.data.message, "danger")
                    }
                }).catch((error) => {
                    Alert.loadingScreen()
                    if (error.response !== undefined) {
                        if (error.response.status === 401) {
                            localStorage.removeItem('token')
                            localStorage.removeItem('role')
                            localStorage.removeItem('name')
                            props.history.push(CONS.URL_LINK + '/login?auth=false')
                        } else if (error.response.status === 501) {
                            Alert.showMsg(error.response.data.message_text, "danger")
                        } else {
                            Alert.showMsg(error.response.data.message, "danger")
                        }
                    }
                })
            } else {
                let re = /^(([^<>()[\]\\.,;:\s@\\"]+(\.[^<>()[\]\\.,;:\s@\\"]+)*)|(\\".+\\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
                if (!re.test(data.email)) {
                    Alert.showMsg("รูปแบบอีเมล์ไม่ถูกต้อง", "danger")
                    return false
                }
                Alert.loadingScreen()
                Log.save(CONS.API_ADMIN + `/user`, { ...data })
                axios.post(CONS.API_ADMIN + "/user", { ...data }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
                    }
                }).then((response) => {
                    Alert.loadingScreen()
                    if (response.data.status === 200) {
                        Alert.showMsg("บันทึกข้อมูลสำเร็จ")
                        props.history.push(CONS.URL_LINK + "/admin")
                    } else {
                        Alert.showMsg(response.data.message, "danger")
                    }
                }).catch((error) => {
                    Alert.loadingScreen()
                    if (error.response !== undefined) {
                        if (error.response.status === 401) {
                            localStorage.removeItem('token')
                            localStorage.removeItem('role')
                            localStorage.removeItem('name')
                            props.history.push(CONS.URL_LINK + '/login?auth=false')
                        } else if (error.response.status === 501) {
                            Alert.showMsg(error.response.data.message_text, "danger")
                        } else {
                            Alert.showMsg(error.response.data.message, "danger")
                        }
                    }
                })
            }
        } else {
            Alert.showMsg("กรุณากรอกข้อมูลให้ครบถ้วน", "danger")
        }
    }

    const loadList = useCallback(() => {
        if (id !== "create") {
            setLoading(true)
            Log.save(CONS.API_ADMIN + `/user/` + id, {})
            axios.get(CONS.API_ADMIN + `/user/` + id, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
                }
            }).then((response) => {
                if (response.data.status === 200) {
                    setData(response.data.data)
                }
                setLoading(false)
            }).catch((error) => {
                if (error.response !== undefined) {
                    if (error.response.status === 401) {
                        localStorage.removeItem('token')
                        localStorage.removeItem('role')
                        localStorage.removeItem('name')
                        props.history.push(CONS.URL_LINK + '/login?auth=false')
                    }
                }
                setLoading(false)
            })
        }
    }, [props.history, id])

    useEffect(() => {
        loadList()
    }, [loadList])

    return (
        <MainLayout active="admin" authorized="admin">
            <Title title={(id === "create" ? "สร้าง" : "แก้ไข") + "ผู้ดูแลระบบ"} />
            <form id="admin-form" autoComplete="off">
                <div className="card mb-3">
                    <div className="card-body p-3 p-md-4">
                        <div className="row g-3">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 col-md-3 col-lg-3 col-xxl-2 text-md-end">
                                        <label className="form-label">ชื่อ-นามสกุล</label>
                                    </div>
                                    <div className="col-12 col-md-5 col-xl-4">
                                        <input type="text" className={"form-control" + (loading ? " placeholder" : "")} name="name" value={data.name} onChange={handleChange} readOnly={loading} required />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 col-md-3 col-lg-3 col-xxl-2 text-md-end">
                                        <label className="form-label">อีเมล</label>
                                    </div>
                                    <div className="col-12 col-md-5 col-xl-4">
                                        <input type="text" className={"form-control" + (loading ? " placeholder" : "")} name="email" value={data.email} onChange={handleChange} readOnly={loading} required />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 col-md-3 col-lg-3 col-xxl-2 text-md-end">
                                        <label className="form-label">ชื่อบัญชีผู้ใช้</label>
                                    </div>
                                    <div className="col-12 col-md-5 col-xl-4">
                                        <input type="text" className={"form-control" + (loading ? " placeholder" : "")} name="username" value={data.username} onChange={handleChange} readOnly={loading} required />
                                    </div>
                                </div>
                            </div>
                            {(id === "create") ?
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12 col-md-3 col-lg-3 col-xxl-2 text-md-end">
                                            <label className="form-label">กำหนดรหัสผ่าน</label>
                                        </div>
                                        <div className="col-12 col-md-5 col-xl-4">
                                            <div className="input-group text-start">
                                                <input type={(show) ? "text" : "password"} className={"form-control rounded-left rounded-right-white" + (loading ? " placeholder" : "")} name="password" value={data.password} onChange={handleChange} readOnly={loading} />
                                                <span className="input-group-text pointer bg-white" onClick={() => setShow(!show)}>
                                                    {(show) ?
                                                        <i className="bi bi-eye-fill"></i> :
                                                        <i className="bi bi-eye-slash-fill"></i>
                                                    }
                                                </span>
                                            </div>
                                            <input type="hidden" name="password-hidden" value={data.password} onChange={() => { }} required />
                                        </div>
                                    </div>
                                </div> : null
                            }
                            {(id === "create") ?
                                <div className="col-12">
                                    <div className="row">
                                        <div className="col-12 col-md-3 col-lg-3 col-xxl-2 text-md-end">
                                            <label className="form-label">ยืนยันรหัสผ่านอีกครั้ง</label>
                                        </div>
                                        <div className="col-12 col-md-5 col-xl-4">
                                            <div className="input-group text-start">
                                                <input type={(show) ? "text" : "password"} className={"form-control rounded-left rounded-right-white" + (loading ? " placeholder" : "")} name="confirm_password" value={data.confirm_password} onChange={handleChange} readOnly={loading} />
                                                <span className="input-group-text pointer bg-white" onClick={() => setShow(!show)}>
                                                    {(show) ?
                                                        <i className="bi bi-eye-fill"></i> :
                                                        <i className="bi bi-eye-slash-fill"></i>
                                                    }
                                                </span>
                                            </div>
                                            <input type="hidden" name="confirm_password-hidden" value={data.confirm_password} onChange={() => { }} required />
                                        </div>
                                        <div className="offset-md-3 offset-lg-3 offset-xxl-2 col-12 col-md-9 col-xxl-10">
                                            <p className="text-start mb-1 mt-2 small">ข้อกำหนดของรหัสผ่าน</p>
                                            <ul className="text-start mb-0 small ps-3">
                                                <li>ต้องมีความยาว 8 ถึง 15 ตัวอักษร</li>
                                                <li>ต้องมีตัวอักษรภาษาอังกฤษตัวพิมพ์เล็กและตัวพิมพ์ใหญ่</li>
                                                <li>ต้องมีตัวเลขอารบิก</li>
                                            </ul>
                                        </div>
                                    </div>
                                </div> : null
                            }
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 col-md-3 col-lg-3 col-xxl-2 text-md-end">
                                        <label className="form-label">เมนูที่เข้าถึงได้</label>
                                    </div>
                                    <div className="col-12 col-md-5 col-xl-4">
                                        {PERMISSION.map((item, i) => {
                                            return (
                                                <div key={i} className="form-check mb-3">
                                                    <input type="checkbox" className={"form-check-input" + (loading ? " placeholder" : "")} id={item.code} value={item.code} onChange={handleChangePermission} checked={data.role.includes(item.code)} disabled={loading} />
                                                    <label className="form-check-label" htmlFor={item.code}>{item.title}</label>
                                                </div>
                                            )
                                        })}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="row g-3">
                <div className="col-auto">
                    <button className="btn btn-success mr-3" onClick={handleSave} disabled={(loading) ? true : false}>บันทึก</button>
                </div>
                <div className="col-auto">
                    <Link className="btn btn-secondary" to={CONS.URL_LINK + "/admin"}>ยกเลิก</Link>
                </div>
            </div>
        </MainLayout>
    )
}

export default withRouter(AdminEntryApp)