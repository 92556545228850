import React, { useState, useEffect } from "react"
import { withRouter, Link } from "react-router-dom"
import CONS from "../../constants"
import Format from "../../functions/format"

function CardList(props) {
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState([])
    const [keyword, setKeyword] = useState("")

    const handleChange = (e) => {
        setKeyword(e.target.value)
    }

    const escapeRegExp = (str) => {
        /* eslint-disable no-useless-escape */
        return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
    }

    useEffect(() => {
        setLoading(props.loading)
    }, [props.loading])

    useEffect(() => {
        setList(props.lists)
    }, [props.lists])

    return (
        <div className="card">
            <div className="card-body p-3 p-md-4">
                <div className="row mb-3 gy-3 align-item-center">
                    <div className="col-12 col-md">
                        <p className="mb-0">ทั้งหมด {(loading) ? "..." : Format.price(list.length)} รายการ</p>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5 col-xl-4 text-end">
                        <div className="input-group">
                            <input type="text" className={"form-control" + (loading ? " placeholder" : "")} placeholder="ค้นหา" name="keyword" value={keyword} onChange={handleChange} readOnly={loading} />
                            <span className="input-group-text bg-white"><i className="bi bi-search"></i></span>
                        </div>
                    </div>
                </div>
                <div className="table-responsive mb-3">
                    <table className="table table-striped">
                        <thead className="text-nowrap">
                            <tr>
                                <th scope="col">ชื่อ-นามสกุล</th>
                                <th scope="col">ชื่อบัญชีผู้ใช้</th>
                                <th scope="col">วันที่สร้าง</th>
                                <th scope="col">เข้าใช้งานล่าสุด</th>
                                <th scope="col" width="150px">ตัวเลือก</th>
                            </tr>
                        </thead>
                        {(loading || list.length === 0) ?
                            <tbody>
                                <tr>
                                    <td colSpan={5}>
                                        {(loading) ?
                                            <div className="text-center">
                                                <div className="spinner-border text-warning" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div> :
                                            <p className="text-muted text-center mb-0">ยังไม่มีข้อมูล</p>
                                        }
                                    </td>
                                </tr>
                            </tbody> :
                            <tbody>
                                {list.map((item, i) => {
                                    var rx = new RegExp(escapeRegExp(keyword), 'i')
                                    if (item.name.match(rx) || item.username.match(rx)) {
                                        return (
                                            <tr key={i}>
                                                <td>{item.name}</td>
                                                <td>{item.username}</td>
                                                <td>{Format.convertToISOToDateString(item.create_date, true)}</td>
                                                <td>{(item.last_login) ? Format.convertToISOToDateString(item.last_login, true) : "-"}</td>
                                                <td >
                                                    <div className="text-nowrap">
                                                        <Link to={CONS.URL_LINK + "/admin/" + item._id} className="btn btn-info-old me-2"><i className="bi bi-pencil-square me-2"></i>แก้ไข</Link>
                                                        <button type="button" className="btn btn-danger" onClick={() => props.handleRemove(item._id)}><i className="bi bi-trash-fill me-2"></i>ลบ</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    } else {
                                        return null
                                    }
                                })}
                            </tbody>
                        }
                    </table>
                </div>
                <div className="row mb-3 gy-3 align-item-center">
                    <div className="col-12 col-md">
                        <p className="mb-0">ทั้งหมด {(loading) ? "..." : Format.price(list.length)} รายการ</p>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5 col-xl-4 text-end">
                        <div className="input-group">
                            <input type="text" className={"form-control" + (loading ? " placeholder" : "")} placeholder="ค้นหา" name="keyword" value={keyword} onChange={handleChange} readOnly={loading} />
                            <span className="input-group-text bg-white"><i className="bi bi-search"></i></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(CardList)