import React, { Fragment } from 'react'
import { withRouter } from "react-router-dom"

function TabsLink(props) {
    const textTitle = () => {
        let title = ""
        switch (props.active) {
            case "asset": title = "สินทรัพย์-หนี้สิน"; break;
            case "income": title = "รายได้-ค่าใช้จ่าย"; break;
            case "target": title = "เป้าหมาย"; break;
            case "calculator": title = "โปรแกรมคำนวณ"; break;
            default: title = "ภาพรวม"; break;
        }
        return title
    }

    return (
        <Fragment>
            <div className="row g-2 justify-content-center mb-4 d-none d-md-flex text-nowrap">
                <div className="col-auto">
                    <div className={"card shadow-sm pointer" + (props.active === "main" ? " bg-dark text-white" : "")} onClick={() => props.handleToggle("main")}>
                        <div className="px-3 py-1">ภาพรวม</div>
                    </div>
                </div>
                <div className="col-auto">
                    <div className={"card shadow-sm pointer" + (props.active === "asset" ? " bg-dark text-white" : "")} onClick={() => props.handleToggle("asset")}>
                        <div className="px-3 py-1">สินทรัพย์-หนี้สิน</div>
                    </div>
                </div>
                <div className="col-auto">
                    <div className={"card shadow-sm pointer" + (props.active === "income" ? " bg-dark text-white" : "")} onClick={() => props.handleToggle("income")}>
                        <div className="px-3 py-1">รายได้-ค่าใช้จ่าย</div>
                    </div>
                </div>
                <div className="col-auto">
                    <div className={"card shadow-sm pointer" + (props.active === "target" ? " bg-dark text-white" : "")} onClick={() => props.handleToggle("target")}>
                        <div className="px-3 py-1">เป้าหมาย</div>
                    </div>
                </div>
                <div className="col-auto">
                    <div className={"card shadow-sm pointer" + (props.active === "calculator" ? " bg-dark text-white" : "")} onClick={() => props.handleToggle("calculator")}>
                        <div className="px-3 py-1">โปรแกรมคำนวณ</div>
                    </div>
                </div>
            </div>
            <div className="dropdown mb-3 d-block d-md-none">
                <div className="dropdown-toggle toggle-end pointer border rounded bg-white px-3 py-2" data-bs-toggle="dropdown" aria-expanded="false">
                    {textTitle()}
                </div>
                <ul className="dropdown-menu w-100" aria-labelledby="dropdownMenuButton1">
                    <li><div className="dropdown-item" onClick={() => props.handleToggle("main")}>ภาพรวม</div></li>
                    <li><div className="dropdown-item" onClick={() => props.handleToggle("asset")}>สินทรัพย์-หนี้สิน</div></li>
                    <li><div className="dropdown-item" onClick={() => props.handleToggle("income")}>รายได้-ค่าใช้จ่าย</div></li>
                    <li><div className="dropdown-item" onClick={() => props.handleToggle("target")}>เป้าหมาย</div></li>
                    <li><div className="dropdown-item" onClick={() => props.handleToggle("calculator")}>โปรแกรมคำนวณ</div></li>
                </ul>
            </div>
        </Fragment>
    )
}

TabsLink.defaultProps = {
    active: "main"
}

export default withRouter(TabsLink)