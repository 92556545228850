import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import Valid from '../../functions/validation'
import Alert from "../../functions/alert"
import CONS from "../../constants"
import Log from "../../functions/log"

function Modal(props) {
    const [data, setData] = useState({
        v: "",
        url: null,
        filename: ""
    })

    const handleChangeImage = async (e) => {
        if (e.target.files.length > 0) {
            if (e.target.files[0].size > 5000000) {
                Alert.showMsg("ขนาดไฟล์เกิน 5 mb", "danger")
                document.getElementById("url").value = ""
                setData({ ...data, url: null, filename: "" })
            } else {
                setData({ ...data, url: e.target.files[0], filename: e.target.files[0].name })
            }
        } else {
            document.getElementById("url").value = ""
            setData({ ...data, url: null, filename: "" })
        }
    }

    const handleSubmit = () => {
        var valid = Valid.form("form-data")
        if (valid) {
            var formData = new FormData()
            formData.append("v", data.v)
            formData.append("url", data.url)
            Log.save(CONS.API_TERM, data)
            axios.put(CONS.API_TERM, formData, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
                }
            }).then((response) => {
                if (response.data.status === 200) {
                    Alert.showMsg("บันทึกเรียบร้อย")
                    var modal = document.getElementById(props.id)
                    modal.classList.remove(`show`)
                    var backdrop = document.getElementById(`modal-backdrop`)
                    backdrop.classList.remove(`show`)
                    document.body.classList.remove(`modal-open`)
                    document.body.style.overflow = null
                    document.body.style.paddingRight = null
                    setTimeout(() => {
                        if (props.callBack !== undefined) props.callBack()
                        ReactDOM.unmountComponentAtNode(document.getElementById('modal-root'))
                        backdrop.parentNode.removeChild(backdrop)
                    }, 300)
                } else {
                    Alert.showMsg("เกิดข้อผิดพลาด บันทึกไม่สำเร็จ", "danger")
                }
            }).catch((error) => {
                if (error.response !== undefined) {
                    if (error.response.status === 401) {
                        localStorage.removeItem('token')
                        localStorage.removeItem('role')
                        localStorage.removeItem('name')
                        props.history.push(CONS.URL_LINK + '/login?auth=false')
                    }
                }
            })
        } else {
            Alert.showMsg("กรุณากรอกข้อมูลให้ครบถ้วน", "danger")
        }
    }

    const handleClose = () => {
        var modal = document.getElementById(props.id)
        modal.classList.remove(`show`)
        var backdrop = document.getElementById(`modal-backdrop`)
        backdrop.classList.remove(`show`)
        document.body.classList.remove(`modal-open`)
        document.body.style.overflow = null
        document.body.style.paddingRight = null
        setTimeout(() => {
            ReactDOM.unmountComponentAtNode(document.getElementById('modal-root'))
            backdrop.parentNode.removeChild(backdrop)
        }, 300)
    }

    useEffect(() => {
        document.body.className = "modal-open"
        document.body.style.overflow = "hidden"
        document.body.style.paddingRight = "0px"
        var check = (document.getElementById("modal-backdrop") === null) ? true : false
        if (check) {
            var backdrop = document.createElement("div")
            backdrop.id = "modal-backdrop"
            backdrop.className = "modal-backdrop fade"
            document.body.appendChild(backdrop)
        }
        document.getElementById(props.id).classList.add("d-block")
        setTimeout(() => {
            document.getElementById(props.id).classList.add("show")
            if (check) {
                backdrop.classList.add("show")
            }
        }, 200)
    }, [props])

    return (
        <div className="modal fade" id={props.id} tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content min-height-90vh">
                    <div className="modal-header border-bottom-0 bg-warning">
                        <h6 className="modal-title">{props.title}</h6>
                        <button type="button" className="btn-close" onClick={handleClose}></button>
                    </div>
                    <div className="modal-body bg-sky border-bottom border-top">
                        <form id="form-data" autoComplete="off">
                            <div className="row g-4">
                                <div className="col-12">เวอร์ชั่นล่าสุด: {(props.last_version) ? props.last_version : "-"}</div>
                                <div className="col-12">
                                    <label className="form-label">เวอร์ชั่น <b className="text-danger">*</b></label>
                                    <input type="text" className="form-control" name="v" value={data.v} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} required />
                                </div>
                                <div className="col-12">
                                    <label className="form-label">ไฟล์แนบ <b className="text-danger">*</b></label>
                                    {(data.filename) ?
                                        <div className="mb-2">{data.filename}</div> : null
                                    }
                                    <div className="mb-2">
                                        <input id="url" name="url" type="file" className="d-none" accept="application/pdf" onChange={handleChangeImage} required />
                                        <label className="btn btn-link text-dark border-dark text-decoration-none" htmlFor="url">เลือกไฟล์</label>
                                    </div>
                                    <p className="very-small text-info-old mb-0">รองรับเฉพาะนามสกุล .pdf เท่านั้น, ขนาดไม่เกิน 5 MB</p>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer border-top-0">
                        <button type="button" className="btn btn-secondary" onClick={handleClose}>ยกเลิก</button>
                        <button type="button" className="btn btn-success" onClick={handleSubmit}><i className="bi bi-check-lg me-2"></i>บันทึก</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

Modal.defaultProps = {
    id: "consentModal",
    title: "เพิ่ม",
    last_version: ""
}

export default Modal