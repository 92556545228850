const API_ENDPONT = process.env.REACT_APP_ENDPOINTE

const CONS = {
    URL_IMAGES: '',
    URL_LINK: '',
    URL_CAMPAIGN: process.env.REACT_APP_CAMPAIGN,
    URL_PUSH_NOTI: process.env.REACT_APP_PUSH_NOTI,
    BASIC_USERNAME: process.env.REACT_APP_USER,
    BASIC_PASSWORD: process.env.REACT_APP_PASS,
    AUTH_PREFIX: 'Bearer ',
    API_LOGIN: API_ENDPONT + "/backend/login",
    API_USER_ACTIVE: API_ENDPONT + "/backend/v3/dashboard/user/active",
    API_TRAMSACTION_COUNT: API_ENDPONT + "/backend/v3/dashboard/transaction",
    API_ACCOUNT_REGISTER: API_ENDPONT + "/backend/v3/dashboard/account/register",
    API_ACCOUNT_ACTIVE: API_ENDPONT + "/backend/v3/dashboard/account/active",
    API_ACCOUNT_TYPE: API_ENDPONT + "/backend/v3/dashboard/account/type",
    API_ACCOUNT_DEVICE: API_ENDPONT + "/backend/v3/dashboard/account/device",
    API_ACCOUNT_CLASS: API_ENDPONT + "/backend/v3/dashboard/account/class",
    API_USED: API_ENDPONT + "/backend/v3/dashboard/used",
    API_OVERALL: API_ENDPONT + "/backend/v3/dashboard/overall",
    API_USER_ACTIVE_FEATURE: API_ENDPONT + "/backend/v3/dashboard/user/active/feature",
    API_TRAMSACTION_COUNT_FEATURE: API_ENDPONT + "/backend/v3/dashboard/transaction/feature",
    API_TRAMSACTION_LEVEL2: API_ENDPONT + "/backend/v3/dashboard/transaction/amount/level2",
    API_TRAMSACTION_LEVEL3: API_ENDPONT + "/backend/v3/dashboard/transaction/amount/level3",
    API_MISSION_STATUS: API_ENDPONT + "/backend/v3/dashboard/mission/status",
    API_MISSION_TOP: API_ENDPONT + "/backend/v3/dashboard/mission/top",
    API_CALCULATE: API_ENDPONT + "/backend/v3/dashboard/calculate",
    API_CONFIG: API_ENDPONT + "/backend/config",
    API_NEWS: API_ENDPONT + "/backend/news",
    API_CLASS: API_ENDPONT + "/backend/classCode",
    API_WHATS_NEW: API_ENDPONT + "/backend/whatnew",
    API_CID_CS: API_ENDPONT + "/backend/cid_cs",
    API_USER: API_ENDPONT + "/backend/user",
    API_ADMIN: API_ENDPONT + "/backend/admin",
    API_TERM: API_ENDPONT + "/backend/term",
    API_CHANGE_PASS: API_ENDPONT + "/backend/change/password",
}
export default CONS