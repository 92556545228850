import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import axios from 'axios'
import MainLayout from "../../components/main-layout"
import Title from "../../components/title"
import Alert from "../../functions/alert"
import Valid from '../../functions/validation'
import CONS from "../../constants"
import Log from "../../functions/log"

function ChangePasswordApp(props) {
    const [show, setShow] = useState(false)
    const [data, setData] = useState({
        oldPassword: "",
        newPassword: "",
        confirm_newPassword: "",
    })

    const handleChange = (e) => {
        data[e.target.name] = (e.target.validity === undefined || !e.target.validity.patternMismatch ? e.target.value : data[e.target.name])
        setData({ ...data })
    }

    const handleSave = () => {
        var valid = Valid.form("admin-form")
        if (valid) {
            if (data.newPassword.length < 8) {
                Alert.showMsg("รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร", "danger")
                return false
            }
            if (data.newPassword.length > 15) {
                Alert.showMsg("รหัสผ่านต้องมีไม่เกิน 15 ตัวอักษร", "danger")
                return false
            }
            if (!(new RegExp("[a-z]").test(data.newPassword)) || !(new RegExp("[A-Z]").test(data.newPassword))) {
                Alert.showMsg("รหัสผ่านต้องประกอบด้วย ตัวอักษรภาษาอังกฤษพิมพ์เล็กและตัวพิมพ์ใหญ่", "danger")
                return false
            }
            if (!(new RegExp("[0-9]").test(data.newPassword))) {
                Alert.showMsg("รหัสผ่านต้องมีตัวเลขอารบิก", "danger")
                return false
            }
            if (data.newPassword !== data.confirm_newPassword) {
                Alert.showMsg("รหัสผ่านไม่ตรงกัน", "danger")
                return false
            }
            Alert.loadingScreen()
            Log.save(CONS.API_ADMIN + "/user/password", { ...data })
            axios.post(CONS.API_ADMIN + "/user/password", { ...data }, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
                }
            }).then((response) => {
                Alert.loadingScreen()
                if (response.data.status === 200) {
                    Alert.showMsg("บันทึกข้อมูลสำเร็จ")
                    setData({
                        oldPassword: "",
                        newPassword: "",
                        confirm_newPassword: "",
                    })
                } else {
                    Alert.showMsg(response.data.message, "danger")
                }
            }).catch((error) => {
                Alert.loadingScreen()
                if (error.response !== undefined) {
                    if (error.response.status === 401) {
                        localStorage.removeItem('token')
                        localStorage.removeItem('role')
                        localStorage.removeItem('name')
                        props.history.push(CONS.URL_LINK + '/login?auth=false')
                    } else if (error.response.status === 501) {
                        Alert.showMsg(error.response.data.message_text, "danger")
                    } else {
                        Alert.showMsg("รหัสผ่านเดิมไม่ถูกต้อง", "danger")
                        // Alert.showMsg(error.response.data.message, "danger")
                    }
                }
            })

        } else {
            Alert.showMsg("กรุณากรอกข้อมูลให้ครบถ้วน", "danger")
        }
    }

    return (
        <MainLayout active="admin" authorized="free">
            <Title title="เปลี่ยนรหัสผ่าน" />
            <form id="admin-form" autoComplete="off">
                <div className="card mb-3">
                    <div className="card-body p-3 p-md-4">
                        <div className="row g-3">
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 col-md-3 col-lg-3 col-xxl-2 text-md-end">
                                        <label className="form-label">รหัสผ่านเดิม</label>
                                    </div>
                                    <div className="col-12 col-md-5 col-xl-4">
                                        <div className="input-group text-start">
                                            <input type={(show) ? "text" : "password"} className="form-control rounded-left rounded-right-white" name="oldPassword" value={data.oldPassword} onChange={handleChange} />
                                            <span className="input-group-text pointer bg-white" onClick={() => setShow(!show)}>
                                                {(show) ?
                                                    <i className="bi bi-eye-fill"></i> :
                                                    <i className="bi bi-eye-slash-fill"></i>
                                                }
                                            </span>
                                        </div>
                                        <input type="hidden" name="oldPassword-hidden" value={data.oldPassword} onChange={() => { }} required />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 col-md-3 col-lg-3 col-xxl-2 text-md-end">
                                        <label className="form-label">กำหนดรหัสผ่านใหม่</label>
                                    </div>
                                    <div className="col-12 col-md-5 col-xl-4">
                                        <div className="input-group text-start">
                                            <input type={(show) ? "text" : "password"} className="form-control rounded-left rounded-right-white" name="newPassword" value={data.newPassword} onChange={handleChange} />
                                            <span className="input-group-text pointer bg-white" onClick={() => setShow(!show)}>
                                                {(show) ?
                                                    <i className="bi bi-eye-fill"></i> :
                                                    <i className="bi bi-eye-slash-fill"></i>
                                                }
                                            </span>
                                        </div>
                                        <input type="hidden" name="newPassword-hidden" value={data.newPassword} onChange={() => { }} required />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12">
                                <div className="row">
                                    <div className="col-12 col-md-3 col-lg-3 col-xxl-2 text-md-end">
                                        <label className="form-label">ยืนยันรหัสผ่านอีกครั้ง</label>
                                    </div>
                                    <div className="col-12 col-md-5 col-xl-4">
                                        <div className="input-group text-start">
                                            <input type={(show) ? "text" : "password"} className="form-control rounded-left rounded-right-white" name="confirm_newPassword" value={data.confirm_newPassword} onChange={handleChange} />
                                            <span className="input-group-text pointer bg-white" onClick={() => setShow(!show)}>
                                                {(show) ?
                                                    <i className="bi bi-eye-fill"></i> :
                                                    <i className="bi bi-eye-slash-fill"></i>
                                                }
                                            </span>
                                        </div>
                                        <input type="hidden" name="confirm_newPassword-hidden" value={data.confirm_newPassword} onChange={() => { }} required />
                                    </div>
                                    <div className="offset-md-3 offset-lg-3 offset-xxl-2 col-12 col-md-9 col-xxl-10">
                                        <p className="text-start mb-1 mt-2 small">ข้อกำหนดของรหัสผ่าน</p>
                                        <ul className="text-start mb-0 small ps-3">
                                            <li>ต้องมีความยาว 8 ถึง 15 ตัวอักษร</li>
                                            <li>ต้องมีตัวอักษรภาษาอังกฤษตัวพิมพ์เล็กและตัวพิมพ์ใหญ่</li>
                                            <li>ต้องมีตัวเลขอารบิก</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className="row g-3">
                <div className="col-auto">
                    <button className="btn btn-success mr-3" onClick={handleSave}>บันทึก</button>
                </div>
            </div>
        </MainLayout>
    )
}

export default withRouter(ChangePasswordApp)