import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import axios from 'axios'
import logo from "../../assets/ico_app.png"
import Alert from "../../functions/alert"
import Valid from '../../functions/validation'
import CONS from "../../constants"
import Log from "../../functions/log"
import PERMISSION from "../../constants/permisstion"

function ResetPasswordApp(props) {
    const [attr, setAttr] = useState({
        show: false,
        isLogin: false
    })
    const [data, setData] = useState({
        password: "",
        newPassword: "",
        confirm_newPassword: "",
    })

    const handleChange = (e) => {
        data[e.target.name] = (e.target.validity === undefined || !e.target.validity.patternMismatch ? e.target.value : data[e.target.name])
        setData({ ...data })
    }

    const handleSave = () => {
        var valid = Valid.form("login-form")
        if (valid) {
            if (data.password) {
                if (data.newPassword.length < 8) {
                    Alert.showMsg("รหัสผ่านต้องมีอย่างน้อย 8 ตัวอักษร", "danger")
                    return false
                }
                if (data.newPassword.length > 15) {
                    Alert.showMsg("รหัสผ่านต้องมีไม่เกิน 15 ตัวอักษร", "danger")
                    return false
                }
                if (!(new RegExp("[a-z]").test(data.newPassword)) || !(new RegExp("[A-Z]").test(data.newPassword))) {
                    Alert.showMsg("รหัสผ่านต้องประกอบด้วย ตัวอักษรภาษาอังกฤษพิมพ์เล็กและตัวพิมพ์ใหญ่", "danger")
                    return false
                }
                if (!(new RegExp("[0-9]").test(data.newPassword))) {
                    Alert.showMsg("รหัสผ่านต้องมีตัวเลขอารบิก", "danger")
                    return false
                }
                if (data.newPassword !== data.confirm_newPassword) {
                    Alert.showMsg("รหัสผ่านไม่ตรงกัน", "danger")
                    return false
                }
                Alert.loadingScreen()
                Log.save(CONS.API_CHANGE_PASS, { ...data })
                axios.post(CONS.API_CHANGE_PASS, { ...data }, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
                    }
                }).then((response) => {
                    Alert.loadingScreen()
                    if (response.data.status === 200) {
                        Alert.showMsg("บันทึกข้อมูลสำเร็จ")
                        localStorage.removeItem("oldPass")
                        localStorage.setItem("token", response.data.token)
                        let role = (localStorage.getItem("role")) ? JSON.parse(localStorage.getItem("role")) : []
                        for (const item of PERMISSION) {
                            var check = role.includes(item.code)
                            if (check) {
                                var firstMenu = (item.code === "etc") ? '/export/log' : item.link
                                break
                            }
                        }
                        props.history.push(CONS.URL_LINK + firstMenu)
                    } else {
                        Alert.showMsg(response.data.message, "danger")
                    }
                }).catch((error) => {
                    Alert.loadingScreen()
                    if (error.response !== undefined) {
                        if (error.response.status === 401) {
                            localStorage.removeItem('token')
                            localStorage.removeItem('role')
                            localStorage.removeItem('name')
                            props.history.push(CONS.URL_LINK + '/login?auth=false')
                        } else if (error.response.status === 501) {
                            Alert.showMsg(error.response.data.message_text, "danger")
                        } else {
                            Alert.showMsg("รหัสผ่านเดิมไม่ถูกต้อง", "danger")
                            // Alert.showMsg(error.response.data.message, "danger")
                        }
                    }
                })
            } else {
                props.history.push(CONS.URL_LINK + "/login")
            }
        } else {
            Alert.showMsg("กรุณากรอกข้อมูลให้ครบถ้วน", "danger")
        }
    }

    useEffect(() => {
        setData((preData) => {
            return { ...preData, password: localStorage.getItem("oldPass") }
        })
    }, [])

    return (
        <div className="container-fluid flex-grow-1">
            <div className="p-2 p-md-3 text-center">
                <div className="row justify-content-center">
                    <div className="col-auto">
                        <form id="login-form">
                            <div className="mb-3 mt-3">
                                <div className="row justify-content-center">
                                    <div className="col-4">
                                        <img src={logo} className="w-100 rounded-25" alt="" />
                                    </div>
                                </div>
                            </div>
                            <h3 className="fw-bold mb-4">ระบบบริหารจัดการข้อมูล</h3>
                            <h5 className="text-start mb-2">กรุณาเปลี่ยนรหัสผ่านใหม่</h5>
                            <p className="text-start mb-1">ข้อกำหนดของรหัสผ่าน</p>
                            <ul className="text-start">
                                <li>ต้องมีความยาว 8 ถึง 15 ตัวอักษร</li>
                                <li>ต้องมีตัวอักษรภาษาอังกฤษตัวพิมพ์เล็กและตัวพิมพ์ใหญ่</li>
                                <li>ต้องมีตัวเลขอารบิก</li>
                            </ul>
                            <div className="input-group mb-3 text-start">
                                <div className="form-floating col">
                                    <input type={(attr.show) ? "text" : "password"} className="form-control rounded-left rounded-right-white" name="newPassword" id="newPassword" placeholder="กำหนดรหัสผ่านใหม่" value={data.newPassword} onChange={handleChange} required data-text="no" />
                                    <label className="text-nowrap" htmlFor="newPassword">กำหนดรหัสผ่านใหม่</label>
                                </div>
                                <span className="input-group-text pointer bg-white" onClick={() => setAttr({ ...attr, show: !attr.show })}>
                                    {(attr.show) ?
                                        <i className="bi bi-eye-fill"></i> :
                                        <i className="bi bi-eye-slash-fill"></i>
                                    }
                                </span>
                                <input type="hidden" name="newPassword-hidden" value={data.newPassword} onChange={() => { }} required />
                            </div>
                            <div className="input-group mb-3 text-start">
                                <div className="form-floating col">
                                    <input type={(attr.show) ? "text" : "password"} className="form-control rounded-left rounded-right-white" name="confirm_newPassword" id="confirm_newPassword" placeholder="ยืนยันรหัสผ่านอีกครั้ง" value={data.confirm_newPassword} onChange={handleChange} required data-text="no" />
                                    <label className="text-nowrap" htmlFor="confirm_newPassword">ยืนยันรหัสผ่านอีกครั้ง</label>
                                </div>
                                <span className="input-group-text pointer bg-white" onClick={() => setAttr({ ...attr, show: !attr.show })}>
                                    {(attr.show) ?
                                        <i className="bi bi-eye-fill"></i> :
                                        <i className="bi bi-eye-slash-fill"></i>
                                    }
                                </span>
                                <input type="hidden" name="confirm_newPassword-hidden" value={data.confirm_newPassword} onChange={() => { }} required />
                            </div>
                            <div className="d-grid">
                                <button type="button" className="btn btn-dark btn-lg" onClick={handleSave}>บันทึก</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
        // <Fragment>
        //     <Title title="เปลี่ยนรหัสผ่าน" />
        //     <form id="admin-form" autoComplete="off">
        //         <div className="card mb-3">
        //             <div className="card-body p-3 p-md-4">
        //                 <div className="row g-3">
        //                     <div className="col-12">
        //                         <div className="row">
        //                             <div className="col-12 col-md-3 col-lg-3 col-xxl-2 text-md-end">
        //                                 <label className="form-label">รหัสผ่านเดิม</label>
        //                             </div>
        //                             <div className="col-12 col-md-5 col-xl-4">
        //                                 <input type="password" className="form-control" name="oldPassword" value={data.oldPassword} onChange={handleChange} required />
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div className="col-12">
        //                         <div className="row">
        //                             <div className="col-12 col-md-3 col-lg-3 col-xxl-2 text-md-end">
        //                                 <label className="form-label">กำหนดรหัสผ่านใหม่</label>
        //                             </div>
        //                             <div className="col-12 col-md-5 col-xl-4">
        //                                 <input type="password" className="form-control" name="newPassword" value={data.newPassword} onChange={handleChange} required />
        //                             </div>
        //                         </div>
        //                     </div>
        //                     <div className="col-12">
        //                         <div className="row">
        //                             <div className="col-12 col-md-3 col-lg-3 col-xxl-2 text-md-end">
        //                                 <label className="form-label">ยืนยันรหัสผ่านอีกครั้ง</label>
        //                             </div>
        //                             <div className="col-12 col-md-5 col-xl-4">
        //                                 <input type="password" className="form-control" name="confirm_newPassword" value={data.confirm_newPassword} onChange={handleChange} required />
        //                             </div>
        //                         </div>
        //                     </div>
        //                 </div>
        //             </div>
        //         </div>
        //     </form>
        //     <div className="row g-3">
        //         <div className="col-auto">
        //             <button className="btn btn-success mr-3" onClick={handleSave}>บันทึก</button>
        //         </div>
        //     </div>
        // </Fragment>
    )
}

export default withRouter(ResetPasswordApp)