import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import Valid from '../../functions/validation'
import Alert from "../../functions/alert"
import CONS from "../../constants"
import Log from "../../functions/log"

function Modal(props) {
    const [data, setData] = useState(props.data)

    const handleChangeImage = async (e) => {
        if (e.target.files.length > 0) {
            let attr = await uploadFileFromData(e.target.files[0])
            if (attr.width === 320 && attr.height === 480) {
                if (e.target.files[0].size > 5000000) {
                    Alert.showMsg("ขนาดไฟล์เกิน 5 mb", "danger")
                    document.getElementById("file").value = ""
                    setData({ ...data, file: null, src: "" })
                } else {
                    setData({ ...data, file: e.target.files[0], src: attr.url })
                }
            } else {
                Alert.showMsg("รูปภาพจะต้องมีขนาด 320x480px เท่านั้น", "danger")
                document.getElementById("file").value = ""
                setData({ ...data, file: null, src: "" })
            }
        } else {
            document.getElementById("file").value = ""
            setData({ ...data, file: null, src: "" })
        }
    }

    const uploadFileFromData = async (file) => {
        let img = new Image();
        let url = URL.createObjectURL(file)
        const promise = new Promise((resolve, reject) => {
            img.onload = function () {
                const width = img.naturalWidth
                const height = img.naturalHeight
                resolve({ width, height, url })
            };
            img.onerror = reject
        })
        img.src = url
        return promise
    }

    const handleCreate = () => {
        var formData = new FormData()
        formData.append("file", data.file)
        formData.append("url", data.url)
        Log.save(CONS.API_NEWS, { url: data.url, file: data.file })
        axios.put(CONS.API_NEWS, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                Alert.showMsg("บันทึกเรียบร้อย")
                var modal = document.getElementById(props.id)
                modal.classList.remove(`show`)
                var backdrop = document.getElementById(`modal-backdrop`)
                backdrop.classList.remove(`show`)
                document.body.classList.remove(`modal-open`)
                document.body.style.overflow = null
                document.body.style.paddingRight = null
                setTimeout(() => {
                    if (props.callBack !== undefined) props.callBack()
                    ReactDOM.unmountComponentAtNode(document.getElementById('modal-root'))
                    backdrop.parentNode.removeChild(backdrop)
                }, 300)
            } else {
                Alert.showMsg("เกิดข้อผิดพลาด บันทึกไม่สำเร็จ", "danger")
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }

    const handleUpdate = () => {
        var formData = {
            id: data.id,
            file: data.file,
            url: data.url,
        }
        Log.save(CONS.API_NEWS, formData)
        if (data.file) {
            formData = new FormData()
            formData.append("id", data.id)
            formData.append("file", data.file)
            formData.append("url", data.url)
        }
        axios.post(CONS.API_NEWS, formData, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                Alert.showMsg("บันทึกเรียบร้อย")
                var modal = document.getElementById(props.id)
                modal.classList.remove(`show`)
                var backdrop = document.getElementById(`modal-backdrop`)
                backdrop.classList.remove(`show`)
                document.body.classList.remove(`modal-open`)
                document.body.style.overflow = null
                document.body.style.paddingRight = null
                setTimeout(() => {
                    if (props.callBack !== undefined) props.callBack()
                    ReactDOM.unmountComponentAtNode(document.getElementById('modal-root'))
                    backdrop.parentNode.removeChild(backdrop)
                }, 300)
            } else {
                Alert.showMsg("เกิดข้อผิดพลาด บันทึกไม่สำเร็จ", "danger")
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }

    const handleSubmit = () => {
        var valid = Valid.form("form-data")
        if (valid) {
            if (data.id) {
                handleUpdate()
            } else {
                handleCreate()
            }
        } else {
            Alert.showMsg("กรุณากรอกข้อมูลให้ครบถ้วน", "danger")
        }
    }

    const handleClose = () => {
        var modal = document.getElementById(props.id)
        modal.classList.remove(`show`)
        var backdrop = document.getElementById(`modal-backdrop`)
        backdrop.classList.remove(`show`)
        document.body.classList.remove(`modal-open`)
        document.body.style.overflow = null
        document.body.style.paddingRight = null
        setTimeout(() => {
            ReactDOM.unmountComponentAtNode(document.getElementById('modal-root'))
            backdrop.parentNode.removeChild(backdrop)
        }, 300)
    }

    useEffect(() => {
        document.body.className = "modal-open"
        document.body.style.overflow = "hidden"
        document.body.style.paddingRight = "0px"
        var check = (document.getElementById("modal-backdrop") === null) ? true : false
        if (check) {
            var backdrop = document.createElement("div")
            backdrop.id = "modal-backdrop"
            backdrop.className = "modal-backdrop fade"
            document.body.appendChild(backdrop)
        }
        document.getElementById(props.id).classList.add("d-block")
        setTimeout(() => {
            document.getElementById(props.id).classList.add("show")
            if (check) {
                backdrop.classList.add("show")
            }
        }, 200)
    }, [props])

    return (
        <div className="modal fade" id={props.id} tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content min-height-90vh">
                    <div className="modal-header border-bottom-0 bg-warning">
                        <h6 className="modal-title">{props.title}</h6>
                        <button type="button" className="btn-close" onClick={handleClose}></button>
                    </div>
                    <div className="modal-body bg-sky border-bottom border-top">
                        <form id="form-data" autoComplete="off">
                            <div className="row g-4">
                                <div className="col-12">
                                    <label className="form-label">รูปภาพข่าว <b className="text-danger">*</b></label>
                                    <div className="mb-2">
                                        <input id="file" name="file" type="file" className="d-none" accept="image/png,image/jpg,image/jpeg" onChange={handleChangeImage} required={(data.id) ? false : true} />
                                        <label className="btn btn-link text-dark border-dark text-decoration-none" htmlFor="file"><i className="bi bi-image me-2"></i>เลือกรูปภาพ</label>
                                    </div>
                                    <p className="very-small text-info-old mb-0">รูปภาพต้องมีขนาด 320x480px, รองรับนามสกุล .jpg .jpeg .png, ขนาดไม่เกิน 5 MB</p>
                                    {(data.image || data.src) ?
                                        <div className="mt-2">
                                            <img src={(data.src) ? data.src : data.image} alt="" width="50%" />
                                        </div> : null
                                    }
                                </div>
                                <div className="col-12">
                                    <label className="form-label">URL</label>
                                    <input type="text" className="form-control" name="url" value={data.url} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} />
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer border-top-0">
                        <button type="button" className="btn btn-secondary" onClick={handleClose}>ยกเลิก</button>
                        <button type="button" className="btn btn-success" onClick={handleSubmit}><i className="bi bi-check-lg me-2"></i>บันทึก</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

Modal.defaultProps = {
    id: "newsModal",
    title: "เพิ่ม",
    data: {
        id: "",
        file: null,
        src: "",
        image: "",
        url: ""
    }
}

export default Modal