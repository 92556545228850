import React, { Fragment, useState, useEffect, useCallback } from "react"
import { withRouter } from "react-router-dom"
import axios from 'axios'
import moment from 'moment'
import DataCard from "./data-card"
import PieTwinChart from "./pie-twin-chart"
import InputDateRange from "../../components/input-date-range"
import CONS from "../../constants"
import Format from "../../functions/format"
import Log from "../../functions/log"

function TargetComponent(props) {
    const [period, setPeriod] = useState({
        startDate: moment().subtract(29, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
    })
    const [incomeType, setIncomeType] = useState("value")
    const [userActive, setUserActive] = useState({
        featureActiveUser: 0,
        registerUser: 0,
        loading: true
    })
    const [transactionCount, setTransactionCount] = useState({
        amount: 0,
        loading: true
    })
    const [missionStatus, setMissionStatus] = useState({
        label: ["ยังไม่เริ่ม", "อยู่ระหว่างออม", "สำเร็จ"],
        amount: [],
        count: [],
    })
    const [missionTop, setMissionTop] = useState({
        loading: true,
        listAmount: [],
        listCount: [],
    })

    const loadUserActive = useCallback(() => {
        Log.save(CONS.API_USER_ACTIVE_FEATURE + `?feature=3&start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {})
        axios.get(CONS.API_USER_ACTIVE_FEATURE + `?feature=3&start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                setUserActive({
                    featureActiveUser: response.data.featureActiveUser,
                    registerUser: response.data.registerUser,
                    loading: false
                })
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }, [props.history, period])

    const loadTransactionCount = useCallback(() => {
        Log.save(CONS.API_TRAMSACTION_COUNT_FEATURE + `?feature=3&start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {})
        axios.get(CONS.API_TRAMSACTION_COUNT_FEATURE + `?feature=3&start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                setTransactionCount({
                    amount: response.data.amount,
                    loading: false
                })
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }, [props.history, period])

    const loadMission = useCallback(() => {
        Log.save(CONS.API_MISSION_STATUS + `?start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {})
        axios.get(CONS.API_MISSION_STATUS + `?start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                let newData = {
                    label: ["ยังไม่เริ่ม", "อยู่ระหว่างออม", "สำเร็จ"],
                    amount: [(response.data.create ? response.data.create.amount : 0), (response.data.process ? response.data.process.amount : 0), (response.data.complete ? response.data.complete.amount : 0)],
                    count: [(response.data.create ? response.data.create.count : 0), (response.data.process ? response.data.process.count : 0), (response.data.complete ? response.data.complete.count : 0)],
                }
                setMissionStatus({ ...newData })
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }, [props.history, period])

    const loadMissionTop = useCallback(() => {
        Log.save(CONS.API_MISSION_TOP + `?sort=${(incomeType === "value" ? 0 : 1)}&start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {})
        axios.get(CONS.API_MISSION_TOP + `?sort=${(incomeType === "value" ? 0 : 1)}&start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                let newData = {
                    listAmount: [],
                    listCount: [],
                    loading: false
                }
                response.data.data.map((item) => {
                    newData.listAmount.push({
                        title: item.name, total: item.amount, type: "", unit: "บาท"
                    })
                    newData.listCount.push({
                        title: item.name, total: item.count, type: "", unit: "รายการ"
                    })
                    return true
                })

                setMissionTop({ ...newData })
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }, [props.history, period, incomeType])

    useEffect(() => {
        loadUserActive()
    }, [loadUserActive])

    useEffect(() => {
        loadTransactionCount()
    }, [loadTransactionCount])

    useEffect(() => {
        loadMission()
    }, [loadMission])

    useEffect(() => {
        loadMissionTop()
    }, [loadMissionTop])

    return (
        <Fragment >
            <div className="row g-3 justify-content-center mb-3">
                <div className="col-12 col-md-5 col-xl-3">
                    <InputDateRange period={period} onChange={(startDate, endDate) => setPeriod({ startDate: startDate, endDate: endDate })} />
                </div>
            </div>
            <div className="row g-3 mb-3">
                <DataCard parentClassName="col-12 col-md-6 col-lg-3" title="จำนวนบัญชีที่มีการใช้งานแอป (Active user)" data={userActive.loading ? "....." : Format.price(userActive.registerUser)} unit="บัญชี" />
                <DataCard parentClassName="col-12 col-md-6 col-lg-3" title="บัญชีที่ใช้ฟีเจอร์นี้ (Feature active user)" data={userActive.loading ? "....." : Format.price(userActive.featureActiveUser)} unit="FAU" />
                <DataCard parentClassName="col-12 col-md-6 col-lg-3" title="บันทึกรายการ (Feature transactions)" data={transactionCount.loading ? "....." : Format.price(transactionCount.amount)} unit="รายการ" />
                <DataCard parentClassName="col-12 col-md-6 col-lg-3" title="ค่าเฉลี่ยการใช้งานฟีเจอร์ ต่อบัญชี" data={transactionCount.loading || userActive.loading ? "....." : Format.price((transactionCount.amount / userActive.featureActiveUser), 2)} unit="รายการ/FAU" />
            </div>
            <div className="row g-3 mb-3">
                <PieTwinChart
                    parentClassName="col-12 col-lg-6"
                    title="รายได้"
                    titleList="อันดับเป้าหมาย"
                    type={incomeType}
                    setType={setIncomeType}
                    backgroundColor={["rgba(212, 212, 212)", "rgb(255, 193, 7)", "rgb(48, 165, 24)"]}
                    labels={missionStatus.label}
                    data={(incomeType === "value") ? missionStatus.amount : missionStatus.count}
                    unit={(incomeType === "value") ? "บาท" : "รายการ"}
                    lists={(incomeType === "value") ? missionTop.listAmount : missionTop.listCount}
                    loading={missionTop.loading}
                />
                <div className="col-12 col-lg-6">
                    <div className="card h-100">
                        <div className="card-body text-center">
                            <p className="text-potion mb-2 text-center">ค่าเฉลี่ยเป้าหมาย</p>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default withRouter(TargetComponent)