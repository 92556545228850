import React, { useState, useEffect, useCallback } from "react"
import ReactDOM from 'react-dom'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import MainLayout from "../../components/main-layout"
import Title from "../../components/title"
import AlertModal from "../../components/alert-modal"
import Alert from "../../functions/alert"
import ActionGroup from "./action-group"
import CardList from "./card-list"
import Modal from "./modal"
import CONS from "../../constants"
import Log from "../../functions/log"

function NewsApp(props) {
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState([])

    const handleCreate = () => {
        ReactDOM.render(
            <Modal
                callBack={() => {
                    loadList()
                }}
            />, document.getElementById('modal-root')
        )
    }

    const handleUpdate = (data) => {
        ReactDOM.render(
            <Modal
                title="แก้ไข"
                data={{ id: data._id, file: null, src: "", image: data.image, url: data.url, }}
                callBack={() => {
                    loadList()
                }}
            />, document.getElementById('modal-root')
        )
    }

    const handleRemove = (id) => {
        ReactDOM.render(
            <AlertModal
                title="ยืนยันการลมหรือไม่ ?"
                btnSubmitClass="btn btn-danger"
                callBack={() => {
                    Log.save(CONS.API_NEWS, { id: id })
                    axios.delete(CONS.API_NEWS, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
                        },
                        data: {
                            id: id
                        }
                    }).then((response) => {
                        if (response.data.status === 200) {
                            Alert.showMsg("บันทึกเรียบร้อย")
                            let index = list.findIndex(x => x._id === id)
                            if (index !== -1) {
                                list.splice(index, 1)
                                setList([...list])
                            }
                        } else {
                            Alert.showMsg("เกิดข้อผิดพลาด บันทึกไม่สำเร็จ", "danger")
                        }
                    }).catch((error) => {
                        if (error.response !== undefined) {
                            if (error.response.status === 401) {
                                localStorage.removeItem('token')
                                localStorage.removeItem('role')
                                localStorage.removeItem('name')
                                props.history.push(CONS.URL_LINK + '/login?auth=false')
                            }
                        }
                    })
                }}
            />, document.getElementById('modal-root')
        )
    }

    const loadList = useCallback(() => {
        Log.save(CONS.API_NEWS + "?important=false", {})
        axios.get(CONS.API_NEWS + "?important=false", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                setList(response.data.news)
            }
            setLoading(false)
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            } else {
                setLoading(false)
            }
        })
    }, [props.history])

    useEffect(() => {
        loadList()
    }, [loadList])

    return (
        <MainLayout active="news" authorized="news">
            <Title title="ข่าวประชาสัมพันธ์" />
            <ActionGroup handleCreate={handleCreate} />
            <CardList loading={loading} lists={list} handleUpdate={handleUpdate} handleRemove={handleRemove} />
        </MainLayout>
    )
}

export default withRouter(NewsApp)