import React, { useState } from "react"
import { Bar } from 'react-chartjs-2'
import 'chartjs-plugin-annotation'
import Format from "../../functions/format"

function BarChart(props) {
    const [type, setType] = useState("month")
    const getOption = () => {
        let year = (new Date()).getFullYear()
        let list = []
        for (var i = year; i >= 2019; i--) {
            list.push(i)
        }
        return list
    }

    const setData = (data, year) => {
        let list = []
        let sum = 0
        if (type === "stack") {
            let currentYear = (new Date()).getFullYear()
            let currentMount = (new Date()).getMonth() + 1
            let i = 1
            for (const item of data) {
                if (year === currentYear && i > currentMount) {
                    list.push(0)
                } else {
                    sum = sum + item
                    list.push(sum)
                }
                i++
            }
        } else {
            list = data
        }
        return list
    }

    const calAvgByYear = (data, year) => {
        let currentYear = (new Date()).getFullYear()
        let currentMount = (new Date()).getMonth() + 1
        let divisor = (year < currentYear) ? 12 : currentMount
        let total = data.reduce((a, b) => a + b, 0)
        let result = (total) ? Math.round(total / divisor) : 0
        return result
    }

    return (
        <div className={props.parentClassName}>
            <div className="card h-100">
                <div className="card-body text-center">
                    <p className="text-potion mb-2 text-center">{props.title}</p>
                    <div className="text-center mb-3">
                        <div className="btn-group">
                            <label className={"btn " + (type === "month" ? "btn-dark" : "btn-outline-dark")} onClick={() => setType("month")}>รายเดือน</label>
                            <label className={"btn px-3 " + (type === "stack" ? "btn-dark" : "btn-outline-dark")} onClick={() => setType("stack")}>&nbsp;&nbsp;สะสม&nbsp;&nbsp;</label>
                        </div>
                    </div>
                    <div className='row justify-content-center mb-2'>
                        <div className='col-6 col-md-4'>
                            <select className="form-control" value={props.label2} onChange={(e) => { props.onChangeYear({ year1: props.label1, year2: +e.target.value }) }}>
                                {getOption().map((year, i) => {
                                    return <option key={i} value={year} disabled={(year === props.label1) ? true : false}>{year}</option>
                                })}
                            </select>
                        </div>
                        <div className='col-6 col-md-4'>
                            <select className="form-control" value={props.label1} onChange={(e) => { props.onChangeYear({ year1: +e.target.value, year2: props.label2 }) }}>
                                {getOption().map((year, i) => {
                                    return <option key={i} value={year} disabled={(year === props.label2) ? true : false}>{year}</option>
                                })}
                            </select>
                        </div>
                    </div>
                    <Bar
                        data={{
                            labels: props.labels,
                            datasets: [{
                                label: props.label2,
                                backgroundColor: props.color2,
                                data: setData(props.data2, props.label2),
                            }, {
                                label: props.label1,
                                backgroundColor: props.color1,
                                data: setData(props.data1, props.label1),
                            }]
                        }}
                        width={props.width}
                        height={props.height}
                        options={{
                            scales: {
                                x: {
                                    grid: {
                                        display: false
                                    },
                                    ticks: {
                                        font: {
                                            family: "Noto SansThai"
                                        },
                                        color: "rgb(20, 139, 158)",
                                    }
                                },
                                y: {
                                    display: false,
                                }
                            },
                            plugins: {
                                // legend: {
                                //     display: false
                                // },
                                tooltip: {
                                    titleFont: {
                                        family: "Noto SansThai"
                                    },
                                    bodyFont: {
                                        family: "Noto SansThai"
                                    }
                                },
                            },
                            responsive: true,
                            maintainAspectRation: false,
                        }}
                    />
                    <div className="card my-2 p-3 text-nowrap text-start">
                        <div className="row fw-bold">
                            <p className="col-12 mb-1">Register User ปี {props.label2}</p>
                            <p className="col-12 mb-0 text-orange">{Format.price(props.data2.reduce((a, b) => a + b, 0))}</p>
                        </div>
                    </div>
                    <div className="card mb-2 p-3 text-nowrap text-start">
                        <div className="row fw-bold">
                            <p className="col-12 mb-1">Register User ปี {props.label1}</p>
                            <p className="col-12 mb-0 text-orange">{Format.price(props.data1.reduce((a, b) => a + b, 0))}</p>
                        </div>
                    </div>
                    <div className="card mb-2 p-3 text-nowrap text-start">
                        <div className="row fw-bold">
                            <p className="col-12 mb-1">อัตราการเติบโต</p>
                            <p className="col-12 mb-0 text-orange">{Format.price((((props.data1.reduce((a, b) => a + b, 0) - props.data2.reduce((a, b) => a + b, 0)) / props.data2.reduce((a, b) => a + b, 0)) * 100), 2)}%</p>
                        </div>
                    </div>
                    <div className="card mb-2 p-3 text-nowrap text-start">
                        <div className="row fw-bold">
                            <p className="col-12 mb-1">ค่าเฉลี่ยปี {props.label2}</p>
                            <p className="col-12 mb-0 text-orange">{Format.price(calAvgByYear(props.data1, props.label1), 2)} บัญชี/เดือน</p>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

BarChart.defaultProps = {
    title: "title",
    color1: "rgb(250, 115, 0)",
    color2: "rgb(43, 43, 43)",
    label1: (new Date()).getFullYear(),
    label2: (new Date()).getFullYear() - 1,
    labels: [],
    data1: [],
    data2: [],
    width: 100,
    height: 40,
    onChangeYear: null
}

export default BarChart