import React, { Fragment, useState, useEffect, useCallback } from "react"
import { withRouter } from "react-router-dom"
import axios from 'axios'
import moment from 'moment'
import Title from "../../components/title"
import DataCard from "./data-card"
import BarChart from "./bar-chart"
import PieChart from "./pie-chart"
// import BarMultipleChart from "./bar-multiple-chart"
import Dataset from "../../functions/dataset"
import InputDateRange from "../../components/input-date-range"
import InputDateRange2 from "../../components/input-date-range2"
import UsageFrequencyChart from "./usage-frequency-chart"
import CONS from "../../constants"
import Format from "../../functions/format"
import Log from "../../functions/log"

function MainComponent(props) {
    const [period, setPeriod] = useState({
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().endOf('month').format('YYYY-MM-DD')
    })
    const [periodUsed, setPeriodUsed] = useState({
        startDate: moment().startOf('month').format('YYYY-MM-DD'),
        endDate: moment().endOf('month').format('YYYY-MM-DD')
    })
    const [type, setType] = useState("hour")
    // const [userActive, setUserActive] = useState({
    //     activeUser: 0,
    //     registerUser: 0,
    //     loading: true
    // })
    // const [userActiveOfYear, setUserActiveOfYear] = useState({
    //     activeUser: 0,
    //     registerUser: 0,
    //     loading: true
    // })
    // const [transactionCount, setTransactionCount] = useState({
    //     amount: 0,
    //     loading: true
    // })
    const [accountRegister, setAccountRegister] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [accountRegister2, setAccountRegister2] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [accountRegisterYear, setAccountRegisterYear] = useState({
        year1: (new Date()).getFullYear(),
        year2: ((new Date()).getFullYear() - 1)
    })
    const [accountActive, setAccountActive] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [accountActive2, setAccountActive2] = useState([0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0])
    const [accountActiveYear, setAccountActiveYear] = useState({
        year1: (new Date()).getFullYear(),
        year2: ((new Date()).getFullYear() - 1)
    })
    const [accountType, setAccountType] = useState([0, 0, 0])
    // const [accountTypePeriod, setAccountTypePeriod] = useState({
    //     startDate: moment().startOf('month').format('YYYY-MM-DD'),
    //     endDate: moment().endOf('month').format('YYYY-MM-DD')
    // })
    const [accountDevice, setAccountDevice] = useState([0, 0, 0])
    // const [accountDevicePeriod, setAccountDevicePeriod] = useState({
    //     startDate: moment().startOf('month').format('YYYY-MM-DD'),
    //     endDate: moment().endOf('month').format('YYYY-MM-DD')
    // })
    const [accountClass, setAccountClass] = useState([0, 0])
    // const [accountClassPeriod, setAccountClassPeriod] = useState({
    //     startDate: moment().startOf('month').format('YYYY-MM-DD'),
    //     endDate: moment().endOf('month').format('YYYY-MM-DD')
    // })
    const [userActiveSecond, setUserActiveSecond] = useState({
        activeUser: 0,
        registerUser: 0,
        loading: true
    })
    const [transactionCountSecond, setTransactionCountSecond] = useState({
        amount: 0,
        loading: true
    })
    const [used, setUsed] = useState({
        loading: true,
        label: [],
        data: [],
        target: []
    })
    const [overall, setOverall] = useState({
        loading: true,
        data: [
            { title: "บันทึกสินทรัพย์-หนี้สิน", uesd: 0, total: 0, icon: "bi-building" },
            { title: "บันทึกรายได้-ค่าใช้จ่าย", uesd: 0, total: 0, icon: "bi-cash-coin" },
            { title: "บันทึกเป้าหมาย", uesd: 0, total: 0, icon: "bi-clipboard-check" },
            { title: "ใช้งานโปรแกรมคำนวณ", uesd: 0, total: 0, icon: "bi-calculator" },
        ]
    })

    // const loadUserActive = useCallback(() => {
    //     Log.save(CONS.API_USER_ACTIVE, {})
    //     axios.get(CONS.API_USER_ACTIVE, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
    //         }
    //     }).then((response) => {
    //         if (response.data.status === 200) {
    //             setUserActive({
    //                 activeUser: response.data.activeUser,
    //                 registerUser: response.data.registerUser,
    //                 loading: false
    //             })
    //         }
    //     }).catch((error) => {
    //         if (error.response !== undefined) {
    //             if (error.response.status === 401) {
    //                 localStorage.removeItem('token')
    //                 localStorage.removeItem('role')
    //                 localStorage.removeItem('name')
    //                 props.history.push(CONS.URL_LINK + '/login?auth=false')
    //             }
    //         }
    //     })
    // }, [props.history])

    // const loadUserActiveOfyear = useCallback(() => {
    //     Log.save(CONS.API_USER_ACTIVE + `?start=${Format.convertDateToISOString(moment().startOf('year').format('YYYY-MM-DD'))}&end=${Format.convertDateToISOString(moment().endOf('year').format('YYYY-MM-DD'), "23.59.59")}`, {})
    //     axios.get(CONS.API_USER_ACTIVE + `?start=${Format.convertDateToISOString(moment().startOf('year').format('YYYY-MM-DD'))}&end=${Format.convertDateToISOString(moment().endOf('year').format('YYYY-MM-DD'), "23.59.59")}`, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
    //         }
    //     }).then((response) => {
    //         if (response.data.status === 200) {
    //             setUserActiveOfYear({
    //                 activeUser: response.data.activeUser,
    //                 registerUser: response.data.registerUser,
    //                 loading: false
    //             })
    //         }
    //     }).catch((error) => {
    //         if (error.response !== undefined) {
    //             if (error.response.status === 401) {
    //                 localStorage.removeItem('token')
    //                 localStorage.removeItem('role')
    //                 localStorage.removeItem('name')
    //                 props.history.push(CONS.URL_LINK + '/login?auth=false')
    //             }
    //         }
    //     })
    // }, [props.history])

    // const loadTransactionCount = useCallback(() => {
    //     Log.save(CONS.API_TRAMSACTION_COUNT + `?start=${Format.convertDateToISOString(moment().startOf('year').format('YYYY-MM-DD'))}&end=${Format.convertDateToISOString(moment().endOf('year').format('YYYY-MM-DD'), "23.59.59")}`, {})
    //     axios.get(CONS.API_TRAMSACTION_COUNT + `?start=${Format.convertDateToISOString(moment().startOf('year').format('YYYY-MM-DD'))}&end=${Format.convertDateToISOString(moment().endOf('year').format('YYYY-MM-DD'), "23.59.59")}`, {
    //         headers: {
    //             'Content-Type': 'application/json',
    //             'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
    //         }
    //     }).then((response) => {
    //         if (response.data.status === 200) {
    //             setTransactionCount({
    //                 amount: response.data.amount,
    //                 loading: false
    //             })
    //         }
    //     }).catch((error) => {
    //         if (error.response !== undefined) {
    //             if (error.response.status === 401) {
    //                 localStorage.removeItem('token')
    //                 localStorage.removeItem('role')
    //                 localStorage.removeItem('name')
    //                 props.history.push(CONS.URL_LINK + '/login?auth=false')
    //             }
    //         }
    //     })
    // }, [props.history])

    const loadAccountRegister = useCallback(() => {
        Log.save(CONS.API_ACCOUNT_REGISTER + `?start=${Format.convertDateToISOString(`${accountRegisterYear.year1}-01-01`)}&end=${Format.convertDateToISOString(`${accountRegisterYear.year1}-12-31`, "23.59.59")}`, {})
        axios.get(CONS.API_ACCOUNT_REGISTER + `?start=${Format.convertDateToISOString(`${accountRegisterYear.year1}-01-01`)}&end=${Format.convertDateToISOString(`${accountRegisterYear.year1}-12-31`, "23.59.59")}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                let dataset = Dataset.name12Month(accountRegisterYear.year1, "date")
                let data = []
                dataset.map((item) => {
                    var date = response.data.data.find(x => x.date === item)
                    if (date) {
                        data.push(date.count)
                    } else {
                        data.push(0)
                    }
                    return true
                })
                setAccountRegister(data)
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
        Log.save(CONS.API_ACCOUNT_REGISTER + `?start=${Format.convertDateToISOString(`${accountRegisterYear.year2}-01-01`)}&end=${Format.convertDateToISOString(`${accountRegisterYear.year2}-12-31`, "23.59.59")}`, {})
        axios.get(CONS.API_ACCOUNT_REGISTER + `?start=${Format.convertDateToISOString(`${accountRegisterYear.year2}-01-01`)}&end=${Format.convertDateToISOString(`${accountRegisterYear.year2}-12-31`, "23.59.59")}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                let dataset = Dataset.name12Month(accountRegisterYear.year2, "date")
                let data = []
                dataset.map((item) => {
                    var date = response.data.data.find(x => x.date === item)
                    if (date) {
                        data.push(date.count)
                    } else {
                        data.push(0)
                    }
                    return true
                })
                setAccountRegister2(data)
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }, [props.history, accountRegisterYear])

    const loadAccountActive = useCallback(() => {
        Log.save(CONS.API_ACCOUNT_ACTIVE + `?start=${Format.convertDateToISOString(`${accountActiveYear.year1}-01-01`)}&end=${Format.convertDateToISOString(`${accountActiveYear.year1}-12-31`, "23.59.59")}`, {})
        axios.get(CONS.API_ACCOUNT_ACTIVE + `?start=${Format.convertDateToISOString(`${accountActiveYear.year1}-01-01`)}&end=${Format.convertDateToISOString(`${accountActiveYear.year1}-12-31`, "23.59.59")}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                let dataset = Dataset.name12Month(accountActiveYear.year1, "date")
                let data = []
                dataset.map((item) => {
                    var date = response.data.data.find(x => x.date === item)
                    if (date) {
                        data.push(date.count)
                    } else {
                        data.push(0)
                    }
                    return true
                })
                setAccountActive(data)
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
        Log.save(CONS.API_ACCOUNT_ACTIVE + `?start=${Format.convertDateToISOString(`${accountActiveYear.year2}-01-01`)}&end=${Format.convertDateToISOString(`${accountActiveYear.year2}-12-31`, "23.59.59")}`, {})
        axios.get(CONS.API_ACCOUNT_ACTIVE + `?start=${Format.convertDateToISOString(`${accountActiveYear.year2}-01-01`)}&end=${Format.convertDateToISOString(`${accountActiveYear.year2}-12-31`, "23.59.59")}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                let dataset = Dataset.name12Month(accountActiveYear.year2, "date")
                let data = []
                dataset.map((item) => {
                    var date = response.data.data.find(x => x.date === item)
                    if (date) {
                        data.push(date.count)
                    } else {
                        data.push(0)
                    }
                    return true
                })
                setAccountActive2(data)
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }, [props.history, accountActiveYear])

    const loadAccountType = useCallback(() => {
        Log.save(CONS.API_ACCOUNT_TYPE, {})
        axios.get(CONS.API_ACCOUNT_TYPE, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                setAccountType([response.data.set, response.data.apple, response.data.guest])
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }, [props.history])

    const loadAccountDevice = useCallback(() => {
        Log.save(CONS.API_ACCOUNT_DEVICE, {})
        axios.get(CONS.API_ACCOUNT_DEVICE, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                setAccountDevice([response.data.ios, response.data.andriod, response.data.huawei])
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }, [props.history])

    const loadAccountClass = useCallback(() => {
        Log.save(CONS.API_ACCOUNT_CLASS, {})
        axios.get(CONS.API_ACCOUNT_CLASS, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                setAccountClass([response.data.active, response.data.inactive])
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }, [props.history])

    const loadUserActiveSecond = useCallback(() => {
        setUserActiveSecond(prev => {
            return {
                activeUser: prev.activeUser,
                registerUser: prev.registerUser,
                loading: true,
            }
        })
        Log.save(CONS.API_USER_ACTIVE + `?start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {})
        axios.get(CONS.API_USER_ACTIVE + `?start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                setUserActiveSecond({
                    activeUser: response.data.activeUser,
                    registerUser: response.data.registerUser,
                    loading: false
                })
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }, [period, props.history])

    const loadTransactionCountSecond = useCallback(() => {
        setTransactionCountSecond(prev => {
            return {
                amount: prev.amount,
                loading: true,
            }
        })
        Log.save(CONS.API_TRAMSACTION_COUNT + `?start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {})
        axios.get(CONS.API_TRAMSACTION_COUNT + `?start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                setTransactionCountSecond({
                    amount: response.data.amount,
                    loading: false
                })
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }, [period, props.history])

    const loadUsed = useCallback(() => {
        setUsed({ loading: true, label: [], data: [], target: [] })
        Log.save(CONS.API_USED + `?start=${Format.convertDateToISOString(periodUsed.startDate)}&end=${Format.convertDateToISOString(periodUsed.endDate, "23.59.59")}`, {})
        axios.get(CONS.API_USED + `?start=${Format.convertDateToISOString(periodUsed.startDate)}&end=${Format.convertDateToISOString(periodUsed.endDate, "23.59.59")}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                let newData = {
                    loading: true,
                    label: [],
                    data: [],
                    target: []
                }
                if (type === "hour") {
                    newData.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    for (let i = 0; i < 24; i++) {
                        let time = (i < 10 ? "0" + i : i).toString()
                        newData.label.push((time + ":00"))
                        let getData = response.data.data.filter(x => x.date.substring(11) === time)
                        newData.data[i] += getData.reduce((n, { count }) => n + count, 0)
                        newData.target.push((time + ":00 น."))
                    }
                } else {
                    newData.data = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0]
                    for (let i = 1; i < 32; i++) {
                        let date = (i < 10 ? "0" + i : i).toString()
                        newData.label.push((date))
                        let getData = response.data.data.filter(x => x.date.substring(10, 8) === date)
                        newData.data[(i - 1)] += getData.reduce((n, { count }) => n + count, 0)
                        newData.target.push(("วันที่ " + date))
                    }
                    // console.log(newData.data )
                }
                newData.loading = false
                //     var start = {
                //         date: period.startDate,
                //         hour: "00"
                //     }
                //     var end = {
                //         date: period.endDate,
                //         hour: "23"
                //     }
                //     if (start.date === "1970-01-01") {
                //         if (response.data.data.length > 0) {
                //             var min = response.data.data.reduce((prev, curr) => prev.date < curr.date ? prev.date : curr.date).split(" ")
                //             start.date = min[0]
                //             start.hour = min[1]
                //         } else {
                //             start.date = (+period.endDate.split("-")[0] - 1) + "-" + period.endDate.split("-")[1] + "-" + period.endDate.split("-")[2]
                //         }
                //     }
                //     var arrDate = Format.getBetweenDate(start.date, end.date)
                //     if (type === "hour") {
                //         for (const item of arrDate) {
                //             for (var i = 0; i < 24; i++) {
                //                 let date = item + " " + (i < 10 ? "0" + i : i)
                //                 var getDate = response.data.data.find(x => x.date === date)
                //                 if (arrDate.length === 1) {
                //                     newData.label.push(((i < 10 ? "0" + i : i) + ":00"))
                //                 } else {
                //                     if (arrDate.length !== 0) {
                //                         if (arrDate.length <= 31) {
                //                             if (i === 12) {
                //                                 newData.label.push(Format.convertDateToText(item))
                //                             } else {
                //                                 newData.label.push("")
                //                             }
                //                         } else if (arrDate.length <= 365) {
                //                             if (item.split("-")[2] === "15" && i === 12) {
                //                                 newData.label.push(Format.convertDateToText(item, false, true))
                //                             } else {
                //                                 newData.label.push("")
                //                             }
                //                         }
                //                     }
                //                 }
                //                 if (getDate) {
                //                     newData.data.push(getDate.count)
                //                 } else {
                //                     newData.data.push(0)
                //                 }
                //                 newData.target.push(Format.convertDateToText(date, true))
                //             }
                //         }
                //     } else {
                //         for (const item of arrDate) {
                //             var getDate2 = response.data.data.filter(x => x.date.split(" ")[0] === item)
                //             if (arrDate.length !== 0) {
                //                 if (arrDate.length <= 31) {
                //                     newData.label.push(Format.convertDateToText(item))
                //                 } else {
                //                     if (item.split("-")[2] === "15") {
                //                         newData.label.push(Format.convertDateToText(item, false, true))
                //                     } else {
                //                         newData.label.push("")
                //                     }
                //                 }
                //             }
                //             if (getDate2.length > 0) {
                //                 newData.data.push(getDate2.reduce((a, b) => a + (b.count || 0), 0))
                //             } else {
                //                 newData.data.push(0)
                //             }
                //             newData.target.push(Format.convertDateToText(item))
                //         }
                //     }
                setUsed({ ...newData })
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }, [periodUsed, type, props.history])

    const loadOverall = useCallback(() => {
        setOverall(prev => {
            return {
                loading: true,
                data: prev.data
            }
        })
        Log.save(CONS.API_OVERALL + `?start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {})
        axios.get(CONS.API_OVERALL + `?start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                setOverall({
                    loading: false,
                    data: [
                        { title: "บันทึกสินทรัพย์-หนี้สิน", uesd: (response.data.assetLiabilities ? response.data.assetLiabilities.count : 0), total: (response.data.assetLiabilities ? response.data.assetLiabilities.amount : 0), icon: "bi-building" },
                        { title: "บันทึกรายได้-ค่าใช้จ่าย", uesd: (response.data.incomeExpense ? response.data.incomeExpense.count : 0), total: (response.data.incomeExpense ? response.data.incomeExpense.amount : 0), icon: "bi-cash-coin" },
                        { title: "บันทึกเป้าหมาย", uesd: (response.data.mission ? response.data.mission.count : 0), total: (response.data.mission ? response.data.mission.amount : 0), icon: "bi-clipboard-check" },
                        { title: "ใช้งานโปรแกรมคำนวณ", uesd: (response.data.calculate ? response.data.calculate : 0), total: 0, disabled: true, icon: "bi-calculator" },
                    ]
                })
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }, [period, props.history])

    // useEffect(() => {
    //     loadUserActive()
    // }, [loadUserActive])

    // useEffect(() => {
    //     loadUserActiveOfyear()
    // }, [loadUserActiveOfyear])

    // useEffect(() => {
    //     loadTransactionCount()
    // }, [loadTransactionCount])

    useEffect(() => {
        loadAccountRegister()
    }, [loadAccountRegister])

    useEffect(() => {
        loadAccountActive()
    }, [loadAccountActive])

    useEffect(() => {
        loadAccountType()
    }, [loadAccountType])

    useEffect(() => {
        loadAccountDevice()
    }, [loadAccountDevice])

    useEffect(() => {
        loadAccountClass()
    }, [loadAccountClass])

    useEffect(() => {
        loadUserActiveSecond()
    }, [loadUserActiveSecond])

    useEffect(() => {
        loadTransactionCountSecond()
    }, [loadTransactionCountSecond])

    useEffect(() => {
        loadUsed()
    }, [loadUsed])

    useEffect(() => {
        loadOverall()
    }, [loadOverall])

    return (
        <Fragment >
            {/* <div className="row g-3 mb-3">
                <DataCard parentClassName="col-12 col-md-6 col-lg-3" title="บัญชีลงทะเบียน (Register user)" data={userActive.loading ? "....." : Format.price(userActive.registerUser)} unit="บัญชี" tooltip="Registered User คือ ผู้ใช้ที่เปิดแอปและผ่านการลงทะเบียนผู้ใช้" />
                <DataCard parentClassName="col-12 col-md-6 col-lg-3" title="บัญชีที่มีการใช้แอป (Active user)" data={userActiveOfYear.loading ? "....." : Format.price(userActiveOfYear.activeUser)} unit="บัญชี" tooltip="Active User คือ ผู้ใช้งานที่มีบันทึกรายการ (รายได้ ค่าใช้จ่าย สินทรัพย์ หนี้สิน้ เป้าหมาย) และ/หรือ ใช้งานโปรแกรมคำนวณ อย่างน้อย 1 ครั้ง ภายใน 1 ปีปฏิทิน (ม.ค. – ธ.ค.)" />
                <DataCard parentClassName="col-12 col-md-6 col-lg-3" title="บัญชีรายการทั้งหมด (Transactions)" data={transactionCount.loading ? "....." : Format.price(transactionCount.amount)} unit="รายการ" tooltip="Monthly Active User (MAU) คือ ผู้ใช้งานที่มีการบันทึกรายการอย่างน้อย 1 ครั้งของช่วงเวลาที่เลือก (นับไม่ช้ำ)" />
                <DataCard parentClassName="col-12 col-md-6 col-lg-3" title="ค่าเฉลี่ยการบันทึกรายการต่อบัญชี" data={transactionCount.loading || userActive.loading ? "....." : Format.price((transactionCount.amount / userActive.activeUser))} unit="รายการ/บัญชี(Active)" />
            </div> */}
            <div className="row g-3 justify-content-center mb-3">
                <div className="col-12 col-md-5 col-xl-3">
                    <InputDateRange period={period} onChange={(startDate, endDate) => setPeriod({ startDate: startDate, endDate: endDate })} />
                </div>
            </div>
            <div className="row g-3 mb-3">
                <DataCard parentClassName="col-12 col-md-6 col-lg-3" title="บัญชีลงทะเบียน (Register user)" data={userActiveSecond.loading ? "....." : Format.price(userActiveSecond.registerUser)} unit="บัญชี" tooltip="คนที่เปิดแอปและผ่านการลงทะเบียนใช้แอปในช่วงเวลาที่เลือก"/>
                <DataCard parentClassName="col-12 col-md-6 col-lg-3" title="บัญชีที่มีการใช้แอป (Active user)" data={userActiveSecond.loading ? "....." : Format.price(userActiveSecond.activeUser)} unit="บัญชี" tooltip="คนที่เข้าใช้แอปอย่างน้อย 1 ครั้งในช่วงเวลาที่เลือก (นับไม่ซ้ำ)"/>
                <DataCard parentClassName="col-12 col-md-6 col-lg-3" title="บันทึกรายการทั้งหมด (Transactions)" data={transactionCountSecond.loading ? "....." : Format.price(transactionCountSecond.amount)} unit="รายการ" tooltip="จำนวนบันทึกรายการที่ Active User สร้างขึ้นในช่วงเวลาที่เลือก"/>
                <DataCard parentClassName="col-12 col-md-6 col-lg-3" title="ค่าเฉลี่ยการบันทึกรายการต่อบัญชี" data={transactionCountSecond.loading || userActiveSecond.loading ? "....." : Format.price((transactionCountSecond.amount || userActiveSecond.activeUser ? transactionCountSecond.amount / userActiveSecond.activeUser : 0), 2)} unit="รายการ/บัญชี(Active)" tooltip="จำนวนบันทึกรายการทั้งหมด / บัญชีที่มีการใช้งาน"/>
            </div>
            <div className="row g-3 mb-4">
                <BarChart
                    parentClassName="col-12 col-md-6"
                    title="Register user"
                    labels={Dataset.name12Month()}
                    onChangeYear={setAccountRegisterYear}
                    label1={accountRegisterYear.year1}
                    label2={accountRegisterYear.year2}
                    data1={accountRegister}
                    data2={accountRegister2}
                />
                <BarChart
                    parentClassName="col-12 col-md-6"
                    title="Active user"
                    color="rgb(48, 165, 24)"
                    labels={Dataset.name12Month()}
                    onChangeYear={setAccountActiveYear}
                    label1={accountActiveYear.year1}
                    label2={accountActiveYear.year2}
                    data1={accountActive}
                    data2={accountActive2}
                />
                <PieChart
                    parentClassName="col-12 col-md-4"
                    title="ประเภทบัญชี"
                    labels={["บัญชี Set member", "บัญชี Apple", "บัญชีชั่วคราว"]}
                    data={accountType}
                // displayDate={true}
                // period={accountTypePeriod}
                // handleChange={(startDate, endDate) => setAccountTypePeriod({ startDate: startDate, endDate: endDate })}
                />
                <PieChart
                    parentClassName="col-12 col-md-4"
                    title="ประเภทอุปกรณ์"
                    backgroundColor={["#212529", "#30A518", "#F50A2A"]}
                    labels={["iOS", "Android", "Huawei"]}
                    data={accountDevice}
                // displayDate={true}
                // period={accountDevicePeriod}
                // handleChange={(startDate, endDate) => setAccountDevicePeriod({ startDate: startDate, endDate: endDate })}
                />
                <PieChart
                    parentClassName="col-12 col-md-4"
                    title="สัดส่วนการระบุ Course code"
                    backgroundColor={["#FFC107", "#82868B"]}
                    labels={["ระบุ Course", "ไม่ระบุ"]}
                    data={accountClass}
                // displayDate={true}
                // period={accountClassPeriod}
                // handleChange={(startDate, endDate) => setAccountClassPeriod({ startDate: startDate, endDate: endDate })}
                />
                {/* <BarMultipleChart
                    parentClassName="col-12 col-md-6"
                    title="การระบุ Course code last 12 months"
                    labels={Dataset.last12Month()}
                    data={[
                        { label: "ระบุ Course", color: "rgb(255, 193, 7)", data: [42689, 65621, 89456, 76333, 112654, 68945, 175654, 67777, 56542, 180056, 114520, 114536] },
                        { label: "ไม่ระบุ", color: "rgb(130, 134, 139)", data: [52689, 75621, 89456, 73333, 122654, 78945, 185654, 77777, 66542, 190056, 124520, 124536] },
                    ]}
                /> */}
            </div>
            <Title title="การใช้งานตามช่วงเวลา" />
            <div className="row g-3 justify-content-center mb-3">
                <div className="col-12 col-md-5 col-xl-3">
                    <InputDateRange2 period={periodUsed} onChange={(startDate, endDate) => setPeriodUsed({ startDate: startDate, endDate: endDate })} />
                </div>
            </div>
            <UsageFrequencyChart
                tooltip="วิธีการคำนวณมาจากผลรวมของ transaction ในระหว่างช่วงเวลา"
                type={type}
                setType={setType}
                usedLoading={used.loading}
                labels={used.label}
                data={used.data}
                target={used.target}
                lists={overall.data}
                loading={overall.loading}
            />
        </Fragment>
    )
}

export default withRouter(MainComponent)