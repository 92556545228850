import React from "react"
import { withRouter } from "react-router-dom"

function ActionGroup(props) {
    return (
        <div className="mb-4">
            <button type="button" className="btn btn-warning" onClick={props.handleCreate}><i className="bi bi-plus-circle-fill me-2"></i>เพิ่ม</button>
        </div>
    )
}

export default withRouter(ActionGroup)