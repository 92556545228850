import React, { useState, useEffect, useCallback } from "react"
import ReactDOM from 'react-dom'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import MainLayout from "../../components/main-layout"
import Title from "../../components/title"
import CardList from "./card-list"
import Modal from "./modal"
import CONS from "../../constants"
import Log from "../../functions/log"

function WhatsNewApp(props) {
    const [loading, setLoading] = useState(true)
    const [count, setCount] = useState(0)
    const [list, setList] = useState([])

    const handleUpdate = (data) => {
        ReactDOM.render(
            <Modal
                title="แก้ไข"
                data={{ id: data._id, title: data.title, feature: data.feature }}
                callBack={() => {
                    loadCID()
                }}
            />, document.getElementById('modal-root')
        )
    }

    const loadList = useCallback((elements, element_total) => {
        setLoading(true)
        Log.save(CONS.API_WHATS_NEW, {})
        axios.get(CONS.API_WHATS_NEW, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                let newElements = []
                for (const item of elements) {
                    let data = response.data.elements.find(x => x.cid_cs_id === item._id)
                    newElements.push({ ...item, feature: (data ? data.feature : "") })
                }
                setCount(element_total)
                setList(newElements)
            }
            setLoading(false)
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            } else {
                setLoading(false)
            }
        })
    }, [props.history])

    const loadCID = useCallback(() => {
        setLoading(true)
        Log.save(CONS.API_CID_CS, {})
        axios.get(CONS.API_CID_CS, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                loadList(response.data.elements, response.data.element_total)
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            } else {
                setLoading(false)
            }
        })
    }, [props.history, loadList])

    useEffect(() => {
        loadCID()
    }, [loadCID])

    return (
        <MainLayout active="whatsNew" authorized="whatsNew">
            <Title title="เวอร์ชั่นแอป" />
            <CardList loading={loading} count={count} lists={list} handleUpdate={handleUpdate} />
        </MainLayout>
    )
}

export default withRouter(WhatsNewApp)