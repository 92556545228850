import React from 'react'
import { BrowserRouter as Router, Switch, Route } from "react-router-dom"
import ScrollToTop from "./components/scroll-to-top"
import CONS from "./constants"
import LoginApp from './pages/login/login-app'
import DashboardApp from './pages/dashboard/app'
import NewsApp from "./pages/news/app"
import AnnouncementApp from "./pages/announcement/app"
import CourseApp from "./pages/course/app"
import WhatsNewApp from "./pages/whats-new/app"
import BlockUserApp from "./pages/block-user/app"
import AdminApp from "./pages/admin/app"
import AdminEntryApp from "./pages/admin-entry/app"
import ChangePasswordApp from "./pages/change-password/app"
import ForceUpdateApp from "./pages/force-update/app"
import ExportLogApp from "./pages/export-log/app"
import ConsentApp from "./pages/consent/app"
import ResetPasswordApp from "./pages/reset-password/app"

function Routes() {
    return (
        <Router>
            <ScrollToTop />
            <Switch>
                <Route exact path={CONS.URL_LINK + "/"}>
                    <LoginApp />
                </Route>
                <Route exact path={CONS.URL_LINK + "/login"}>
                    <LoginApp />
                </Route>
                <Route exact path={CONS.URL_LINK + "/dashboard/:type"}>
                    <DashboardApp />
                </Route>
                <Route exact path={CONS.URL_LINK + "/news"}>
                    <NewsApp />
                </Route>
                <Route exact path={CONS.URL_LINK + "/announcement"}>
                    <AnnouncementApp />
                </Route>
                <Route exact path={CONS.URL_LINK + "/course"}>
                    <CourseApp />
                </Route>
                <Route exact path={CONS.URL_LINK + "/whatsNew"}>
                    <WhatsNewApp />
                </Route>
                <Route exact path={CONS.URL_LINK + "/blockUser"}>
                    <BlockUserApp />
                </Route>
                <Route exact path={CONS.URL_LINK + "/consent"}>
                    <ConsentApp />
                </Route>
                <Route exact path={CONS.URL_LINK + "/force-update"}>
                    <ForceUpdateApp />
                </Route>
                <Route exact path={CONS.URL_LINK + "/admin"}>
                    <AdminApp />
                </Route>
                <Route exact path={CONS.URL_LINK + "/admin/:id"}>
                    <AdminEntryApp />
                </Route>
                <Route exact path={CONS.URL_LINK + "/change/password"}>
                    <ChangePasswordApp />
                </Route>
                <Route exact path={CONS.URL_LINK + "/reset/password"}>
                    <ResetPasswordApp />
                </Route>
                <Route exact path={CONS.URL_LINK + "/export/log"}>
                    <ExportLogApp />
                </Route>
            </Switch>
        </Router>
    )
}

export default Routes