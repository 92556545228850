import React from "react"
import { withRouter, Link } from "react-router-dom"
import CONS from "../../constants"

function ActionGroup(props) {
    return (
        <div className="mb-4">
            <Link to={CONS.URL_LINK + "/admin/create"} className={"btn btn-warning"}><i className="bi bi-plus-circle-fill me-2"></i>เพิ่ม</Link>
        </div>
    )
}

export default withRouter(ActionGroup)