import React from 'react'
import { withRouter } from "react-router-dom"

function Title(props) {
    return (
        <div className="row g-2 align-items-center justify-content-center mb-3">
            <div className="col d-none d-md-block">
                <div className="border-top"></div>
            </div>
            <div className="col-auto">
                <h4 className="fw-bold mb-0 text-nowrap">{props.title}</h4>
            </div>
            <div className="col d-none d-md-block">
                <div className="border-top"></div>
            </div>
        </div>
    )
}

Title.defaultProps = {
    title: "title"
}

export default withRouter(Title)