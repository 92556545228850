import React, { useState, useEffect, useCallback } from "react"
import ReactDOM from 'react-dom'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import MainLayout from "../../components/main-layout"
import Title from "../../components/title"
import CardList from "./card-list"
import ActionGroup from "./action-group"
import Modal from "./modal"
import Alert from "../../functions/alert"
import AlertModal from "../../components/alert-modal"
import CONS from "../../constants"
import Log from "../../functions/log"

function ForceUpdateApp(props) {
    const [loading, setLoading] = useState(true)
    const [count, setCount] = useState(0)
    const [list, setList] = useState([])

    const handleCreate = () => {
        ReactDOM.render(
            <Modal
                last_version={(list.length > 2) ? list[2].title : ""}
                callBack={() => {
                    loadCID()
                }}
            />, document.getElementById('modal-root')
        )
    }

    const handleUpdate = (data, status) => {
        ReactDOM.render(
            <AlertModal
                title={`ยืนยันการ ${(status) ? "Open Update" : "Force Update"} หรือไม่ ?`}
                btnSubmitClass={(status) ? "btn btn-info-old" : "btn btn-danger"}
                callBack={() => {
                    Log.save(CONS.API_CID_CS, {
                        _id: data._id,
                        status: status
                    })
                    axios.post(CONS.API_CID_CS, {
                        _id: data._id,
                        status: status
                    }, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
                        }
                    }).then((response) => {
                        if (response.data.status === 200) {
                            Alert.showMsg("บันทึกเรียบร้อย")
                            loadCID()
                        } else {
                            Alert.showMsg("เกิดข้อผิดพลาด บันทึกไม่สำเร็จ", "danger")
                        }
                    }).catch((error) => {
                        if (error.response !== undefined) {
                            if (error.response.status === 401) {
                                localStorage.removeItem('token')
                                localStorage.removeItem('role')
                                localStorage.removeItem('name')
                                props.history.push(CONS.URL_LINK + '/login?auth=false')
                            }
                        }
                    })
                }}
            />, document.getElementById('modal-root')
        )
    }

    const loadCID = useCallback(() => {
        setLoading(true)
        Log.save(CONS.API_CID_CS, {})
        axios.get(CONS.API_CID_CS, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                setCount(response.data.element_total)
                setList(response.data.elements)
            }
            setLoading(false)
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            } else {
                setLoading(false)
            }
        })
    }, [props.history])

    useEffect(() => {
        loadCID()
    }, [loadCID])

    return (
        <MainLayout active="forceUpdate" authorized="forceUpdate">
            <Title title="Force Update" />
            <ActionGroup handleCreate={handleCreate} />
            <CardList loading={loading} count={count} lists={list} handleUpdate={handleUpdate} />
        </MainLayout>
    )
}

export default withRouter(ForceUpdateApp)