const moment = require('moment')

var Format = {
    price(number, decimal = 0) {
        number = (number !== undefined && number !== "" && number !== null) ? number : 0
        number = Intl.NumberFormat('en-US', { currency: 'USD', minimumFractionDigits: parseInt(decimal), maximumFractionDigits: parseInt(decimal), }).format(parseFloat(number))
        return number
    },

    shotNumber(number) {
        number = (number !== undefined && number !== "" && number !== null && number !== Infinity && !isNaN(number)) ? number : 0
        var unit = ""
        if (number >= 1000000) {
            number = number / 1000000
            unit = "M"
        } else if (number >= 1000) {
            number = number / 1000
            unit = "K"
        }

        var decimal = Format.countDecimals(number)
        if (decimal > 2) {
            decimal = 2
            number = number.toString().split(".")[0] + "." + number.toString().split(".")[1].substring(0, 2)
        }

        number = Intl.NumberFormat('en-US', { currency: 'USD', minimumFractionDigits: decimal, maximumFractionDigits: decimal }).format(parseFloat(number)) + unit
        return number
    },

    countDecimals(value) {
        if (Math.floor(value) === value) return 0
        return value.toString().split(".")[1].length || 0
    },

    dateSplit(dateTime) {
        dateTime = dateTime.split(" ")
        var date = dateTime[0].split("/")
        var time = dateTime[1].split(":")
        return {
            day: date[0],
            month: date[1],
            year: date[2],
            hour: time[0],
            minute: time[1],
            second: time[2],
        }
    },

    getBase64(file) {
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = function () {
                resolve(reader.result)
            }
            reader.onerror = function (error) {
                reject(error)
            }
        })
    },

    arrayToString(arr, field = null, separator = ", ") {
        var data = ""
        arr.map((item) => {
            return data += (data ? separator : "") + (field ? item[field] : item)
        })
        return data
    },

    formatDateTime(dateTime, type = 'asc') {
        dateTime = dateTime.split(" ")
        var time = dateTime[1]
        if (type === "asc") {
            var date1 = dateTime[0].split("/")
            return date1[2] + "-" + date1[1] + "-" + date1[0] + " " + time
        } else {
            var date2 = dateTime[0].split("-")
            return date2[2] + "/" + date2[1] + "/" + date2[0] + " " + time
        }
    },

    convertDateToISOString(date, time = "00:00:00") {
        return (moment(date + " " + time, "YYYY-MM-DD hh:mm:ss").add(7, 'hour')["_d"]).toISOString()
    },

    convertToISOToDateString(date, time = false) {
        date = new Date(date)
        return (time) ? moment(date).format('DD/MM/YYYY HH:mm:ss') : moment(date).format('DD/MM/YYYY')
    },

    getLastMonths(count = 12) {
        var dateNow = new Date()
        var year = dateNow.getFullYear()
        var month = dateNow.getMonth() + 1
        for (var i = count; i > 1; i--) {
            month = month - 1
            if (month === 0) {
                month = 12
                year = year - 1
            }
        }
        return year + "-" + (month < 10 ? "0" + month : month)
    },

    getBetweenDate(startDate, endDate, steps = 1) {
        startDate = moment(startDate, "YYYY-MM-DD")["_d"]
        endDate = moment(endDate, "YYYY-MM-DD")["_d"]
        let dateArray = []
        let currentDate = startDate
        while (currentDate <= endDate) {
            dateArray.push(Format.formatDate(currentDate))
            currentDate.setUTCDate(currentDate.getUTCDate() + steps)
        }
        return dateArray
    },

    formatDate(date) {
        var dd = date.getDate()
        var mm = date.getMonth() + 1
        var yy = date.getFullYear()
        if (dd < 10) dd = "0" + dd
        if (mm < 10) mm = "0" + mm
        return yy + "-" + mm + "-" + dd
    },

    convertDateToText(date, time = false, cutDay = false) {
        const monthList = ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ต.", "ส.ต.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."]
        if (time) {
            var date1 = date.split(" ")
            var date2 = date1[0].split("-")
            return date2[2] + " " + monthList[+(date2[1] - 1)] + (+date2[0] + 543).toString().substring(2, 4) + " " + date1[1]
        } else {
            var date3 = date.split("-")
            return (cutDay ? "" : date3[2] + " ") + monthList[+(date3[1] - 1)] + (+date3[0] + 543).toString().substring(2, 4)
        }
    },

    genPagination(index = 1, size = 10, count = 0) {
        let pageNum = Math.ceil(count / size)
        let list = []
        if (size !== undefined && size !== "" && index !== undefined && index !== "") {
            for (var x = parseInt(index) - 4; x <= pageNum; x++) {
                if (x <= 0) continue
                if (list.length > 3 && (x - parseInt(index)) < 2 && x < pageNum) list.shift()
                if (list.length >= 5) break
                list.push(x)
            }
        }
        return list
    }
}

module.exports = Format