import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import MainLayout from "../../components/main-layout"
import TabsLink from "./tabs-link"
import Title from "../../components/title"
import MainComponent from "./main-component"
import AssetComponent from "./asset-component"
import IncomeComponent from "./income-component"
import TargetComponent from "./target-component"
import CalculatorComponent from "./calculator-component"
import CONS from "../../constants"

function DashboardApp(props) {
    const [type, setType] = useState((props.match.params.type.match(/main|asset|income|target|calculator/g)) ? props.match.params.type : "main")

    const textHeader = () => {
        let header = ""
        switch (type) {
            case "asset": header = "การใช้งานสินทรัพย์-หนี้สิน ตามช่วงเวลา"; break;
            case "income": header = "การใช้งานรายได้-ค่าใช้จ่าย ตามช่วงเวลา"; break;
            case "target": header = "การใช้งานเป้าหมาย ตามช่วงเวลา"; break;
            case "calculator": header = "การใช้งานโปรแกรมคำนวณ ตามช่วงเวลา"; break;
            default: header = "สถิติใช้งานสะสม ตั้งแต่เปิดให้บริการ"; break;
        }
        return header
    }

    const handleToggle = (newType) => {
        setType(newType)
        props.history.push(CONS.URL_LINK + `/dashboard/${newType}`, newType)
    }

    useEffect(() => {
        window.onpopstate = (e) => {
            if (e.state.state) {
                if (e.state.state !== type) {
                    setType(e.state.state)
                }
            } else {
                var path = e.currentTarget.location.pathname
                setType(path.substring(path.lastIndexOf("/") + 1))
            }
        }
    })

    return (
        <MainLayout active="dashboard" authorized="dashboard">
            <TabsLink active={type} handleToggle={handleToggle} />
            <Title title={textHeader()} />
            {(type === "main") ? <MainComponent /> : null}
            {(type === "asset") ? <AssetComponent /> : null}
            {(type === "income") ? <IncomeComponent /> : null}
            {(type === "target") ? <TargetComponent /> : null}
            {(type === "calculator") ? <CalculatorComponent /> : null}
        </MainLayout>
    )
}

export default withRouter(DashboardApp)