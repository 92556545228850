import React from 'react'
import { withRouter } from "react-router-dom"
import { Line } from 'react-chartjs-2'
import 'chartjs-plugin-annotation'
import Format from "../../functions/format"
import ReactTooltip from "react-tooltip"

function UsageFrequencyChart(props) {
    return (
        <div className={props.parentClassName}>
            <div className="card h-100">
                <div className="card-body text-center">
                    <p className="text-potion">ความถี่การใช้งาน {(props.tooltip) ? <i className="bi bi-exclamation-circle-fill" data-for="main" data-tip={props.tooltip}></i> : null}</p>
                    {(props.tooltip) ? <ReactTooltip id="main" place="right" effect="solid" multiline={true} /> : null}
                    <div className="mb-3">
                        <div className="btn-group">
                            <label className={"btn " + (props.type === "hour" ? "btn-dark" : "btn-outline-dark")} onClick={() => props.setType("hour")}>ชั่วโมง</label>
                            <label className={"btn px-3 " + (props.type === "day" ? "btn-dark" : "btn-outline-dark")} onClick={() => props.setType("day")}>วันที่</label>
                        </div>
                    </div>
                    <div className="row g-3">
                        <div className="col-12 col-lg-7 col-xl-8">
                            <div className="position-relative">
                                {props.usedLoading ? (
                                    <div className="position-absolute bg-white w-100 h-100">
                                        <div className="row mx-0 h-100 align-items-center">
                                            <div className="col-12 text-ccenter">
                                                <div
                                                    className="spinner-border text-secondary"
                                                    role="status"
                                                ></div>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                                <Line
                                    data={{
                                        labels: props.labels,
                                        datasets: [{
                                            label: "",
                                            backgroundColor: props.backgroundColor,
                                            borderColor: props.color,
                                            fill: true,
                                            fillColor: "#ffff00",
                                            tension: 0.1,
                                            data: props.data,
                                            target: props.target
                                        }]
                                    }}
                                    width={props.width}
                                    height={props.height}
                                    options={{
                                        // elements: {
                                        //     point:{
                                        //         radius: 0
                                        //     }
                                        // },
                                        scales: {
                                            x: {
                                                grid: {
                                                    display: false
                                                },
                                                ticks: {
                                                    font: {
                                                        family: "Noto SansThai"
                                                    },
                                                    color: "rgb(20, 139, 158)",
                                                }
                                            },
                                            y: {
                                                display: false,
                                            }
                                        },
                                        plugins: {
                                            legend: {
                                                display: false
                                            },
                                            tooltip: {
                                                titleFont: {
                                                    family: "Noto SansThai"
                                                },
                                                bodyFont: {
                                                    family: "Noto SansThai"
                                                },
                                                callbacks: {
                                                    title: function (context) {
                                                        return context[0].dataset.target[context[0].dataIndex] || '';
                                                    },
                                                    label: function (context) {
                                                        return context.formattedValue;
                                                    }
                                                }
                                            },
                                        },
                                        responsive: true,
                                        maintainAspectRation: false,
                                    }}
                                />
                            </div>
                        </div>
                        <div className="col-12 col-lg-5 col-xl-4">
                            <div className="row g-3 g-lg-1 h-100 text-nowrap">
                                {props.lists.map((item, i) => {
                                    return (
                                        <div className="col-12" key={i}>
                                            <div className="row align-items-center">
                                                <div className="col-auto d-none d-md-block">
                                                    <i className={`bi ${item.icon} text-gray fs-1`}></i>
                                                </div>
                                                <div className="col">
                                                    <div className="row">
                                                        <div className="col-auto">{item.title}</div>
                                                        <div className="col text-end">{(props.loading ? "....." : Format.price(item.uesd))} รายการ</div>
                                                    </div>
                                                    <div className="row">
                                                        <div className="col-auto text-muted">{item.title}</div>
                                                        <div className="col text-end text-potion">{(item.disabled) ? "" : (props.loading ? "....." : Format.price(item.total)) + " บัญชี"}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

UsageFrequencyChart.defaultProps = {
    parentClassName: "mb-4",
    color: "rgb(255, 193, 7)",
    backgroundColor: "rgb(255, 238, 189)",
    labels: [],
    data: [],
    target: [],
    lists: [],
    loading: false,
    usedLoading: false,
    width: 100,
    height: 40,
    tooltip: "",
}

export default withRouter(UsageFrequencyChart)