import React, { Fragment, useState, useEffect, useCallback } from "react"
import { withRouter } from "react-router-dom"
import axios from 'axios'
import moment from 'moment'
import DataCard from "./data-card"
import PieTwinChart from "./pie-twin-chart"
import InputDateRange from "../../components/input-date-range"
import CONS from "../../constants"
import Format from "../../functions/format"
import Log from "../../functions/log"

function AssetComponent(props) {
    const [period, setPeriod] = useState({
        startDate: moment().subtract(29, 'days').format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
    })
    const [assetType, setAssetType] = useState("value")
    const [debtType, setDebtType] = useState("value")
    const [userActive, setUserActive] = useState({
        featureActiveUser: 0,
        registerUser: 0,
        loading: true
    })
    const [transactionCount, setTransactionCount] = useState({
        amount: 0,
        loading: true
    })
    const [transactionAsset2, setTransactionAsset2] = useState({
        label: [],
        amount: [],
        count: [],
    })
    const [transactionLiabilities2, setTransactionLiabilities2] = useState({
        label: [],
        amount: [],
        count: [],
    })
    const [transactionAsset3, setTransactionAsset3] = useState({
        loading: true,
        listAmount: [],
        listCount: [],
    })
    const [transactionLiabilities3, setTransactionLiabilities3] = useState({
        loading: true,
        listAmount: [],
        listCount: [],
    })

    const loadUserActive = useCallback(() => {
        Log.save(CONS.API_USER_ACTIVE_FEATURE + `?feature=1&start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {})
        axios.get(CONS.API_USER_ACTIVE_FEATURE + `?feature=1&start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                setUserActive({
                    featureActiveUser: response.data.featureActiveUser,
                    registerUser: response.data.registerUser,
                    loading: false
                })
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }, [props.history, period])

    const loadTransactionCount = useCallback(() => {
        Log.save(CONS.API_TRAMSACTION_COUNT_FEATURE + `?feature=1&start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {})
        axios.get(CONS.API_TRAMSACTION_COUNT_FEATURE + `?feature=1&start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                setTransactionCount({
                    amount: response.data.amount,
                    loading: false
                })
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }, [props.history, period])

    const loadTransactionAsset2 = useCallback(() => {
        Log.save(CONS.API_TRAMSACTION_LEVEL2 + `?feature=1&start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {})
        axios.get(CONS.API_TRAMSACTION_LEVEL2 + `?feature=1&start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                let newData = {
                    label: [],
                    amount: [],
                    count: []
                }

                response.data.data.map((item) => {
                    newData.label.push(item.name)
                    newData.amount.push(item.amount)
                    newData.count.push(item.count)
                    return true
                })

                setTransactionAsset2({ ...newData })
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }, [props.history, period])

    const loadTransactionLiabilities2 = useCallback(() => {
        Log.save(CONS.API_TRAMSACTION_LEVEL2 + `?feature=2&start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {})
        axios.get(CONS.API_TRAMSACTION_LEVEL2 + `?feature=2&start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                let newData = {
                    label: [],
                    amount: [],
                    count: []
                }

                response.data.data.map((item) => {
                    newData.label.push(item.name)
                    newData.amount.push(item.amount)
                    newData.count.push(item.count)
                    return true
                })

                setTransactionLiabilities2({ ...newData })
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }, [props.history, period])

    const loadTransactionAsset3 = useCallback(() => {
        Log.save(CONS.API_TRAMSACTION_LEVEL3 + `?feature=1&sort=${(assetType === "value" ? 0 : 1)}&start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {})
        axios.get(CONS.API_TRAMSACTION_LEVEL3 + `?feature=1&sort=${(assetType === "value" ? 0 : 1)}&start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                let newData = {
                    listAmount: [],
                    listCount: [],
                    loading: false
                }
                response.data.data.map((item) => {
                    newData.listAmount.push({
                        title: item.name, total: item.amount, type: "", unit: "บาท"
                    })
                    newData.listCount.push({
                        title: item.name, total: item.count, type: "", unit: "รายการ"
                    })
                    return true
                })

                setTransactionAsset3({ ...newData })
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }, [props.history, period, assetType])

    const loadTransactionLiabilities3 = useCallback(() => {
        Log.save(CONS.API_TRAMSACTION_LEVEL3 + `?feature=2&sort=${(debtType === "value" ? 0 : 1)}&start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {})
        axios.get(CONS.API_TRAMSACTION_LEVEL3 + `?feature=2&sort=${(debtType === "value" ? 0 : 1)}&start=${Format.convertDateToISOString(period.startDate)}&end=${Format.convertDateToISOString(period.endDate, "23.59.59")}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                let newData = {
                    listAmount: [],
                    listCount: [],
                    loading: false
                }
                response.data.data.map((item) => {
                    newData.listAmount.push({
                        title: item.name, total: item.amount, type: "", unit: "บาท"
                    })
                    newData.listCount.push({
                        title: item.name, total: item.count, type: "", unit: "รายการ"
                    })
                    return true
                })

                setTransactionLiabilities3({ ...newData })
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }, [props.history, period, debtType])

    useEffect(() => {
        loadUserActive()
    }, [loadUserActive])

    useEffect(() => {
        loadTransactionCount()
    }, [loadTransactionCount])

    useEffect(() => {
        loadTransactionAsset2()
    }, [loadTransactionAsset2])

    useEffect(() => {
        loadTransactionLiabilities2()
    }, [loadTransactionLiabilities2])

    useEffect(() => {
        loadTransactionAsset3()
    }, [loadTransactionAsset3])

    useEffect(() => {
        loadTransactionLiabilities3()
    }, [loadTransactionLiabilities3])

    return (
        <Fragment >
            <div className="row g-3 justify-content-center mb-3">
                <div className="col-12 col-md-5 col-xl-3">
                    <InputDateRange period={period} onChange={(startDate, endDate) => setPeriod({ startDate: startDate, endDate: endDate })} />
                </div>
            </div>
            <div className="row g-3 mb-3">
                <DataCard parentClassName="col-12 col-md-6 col-lg-3" title="จำนวนบัญชีที่มีการใช้งานแอป (Active user)" data={userActive.loading ? "....." : Format.price(userActive.registerUser)} unit="บัญชี" />
                <DataCard parentClassName="col-12 col-md-6 col-lg-3" title="บัญชีที่ใช้ฟีเจอร์นี้ (Feature active user)" data={userActive.loading ? "....." : Format.price(userActive.featureActiveUser)} unit="FAU" />
                <DataCard parentClassName="col-12 col-md-6 col-lg-3" title="บันทึกรายการ (Feature transactions)" data={transactionCount.loading ? "....." : Format.price(transactionCount.amount)} unit="รายการ" />
                <DataCard parentClassName="col-12 col-md-6 col-lg-3" title="ค่าเฉลี่ยการใช้งานฟีเจอร์ ต่อบัญชี" data={transactionCount.loading || userActive.loading ? "....." : Format.price((transactionCount.amount / userActive.featureActiveUser), 2)} unit="รายการ/FAU" />
            </div>
            <div className="row g-3 mb-3">
                <PieTwinChart
                    parentClassName="col-12 col-lg-6"
                    title="สินทรัพย์"
                    titleList="อันดับสินทรัพย์"
                    type={assetType}
                    setType={setAssetType}
                    backgroundColor={["rgb(48, 165, 24)", "rgb(255, 193, 7)", "rgb(13, 109, 253)"]}
                    labels={transactionAsset2.label}
                    data={(assetType === "value") ? transactionAsset2.amount : transactionAsset2.count}
                    unit={(assetType === "value") ? "บาท" : "รายการ"}
                    lists={(assetType === "value") ? transactionAsset3.listAmount : transactionAsset3.listCount}
                    loading={transactionAsset3.loading}
                />
                <PieTwinChart
                    parentClassName="col-12 col-lg-6"
                    title="หนี้สิน"
                    titleList="อันดับหนี้สิน"
                    type={debtType}
                    setType={setDebtType}
                    backgroundColor={["rgb(255, 0, 0)", "rgb(255, 193, 7)"]}
                    labels={transactionLiabilities2.label}
                    data={(debtType === "value") ? transactionLiabilities2.amount : transactionLiabilities2.count}
                    unit={(debtType === "value") ? "บาท" : "รายการ"}
                    lists={(debtType === "value") ? transactionLiabilities3.listAmount : transactionLiabilities3.listCount}
                    loading={transactionLiabilities3.loading}
                />
            </div>
        </Fragment>
    )
}

export default withRouter(AssetComponent)