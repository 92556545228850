import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import ReactTooltip from 'react-tooltip'

function CardList(props) {
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState([])

    useEffect(() => {
        setLoading(props.loading)
    }, [props.loading])

    useEffect(() => {
        setList(props.lists)
    }, [props.lists])

    return (
        <div className="row g-3 mb-4">
            {(loading || list.length === 0) ?
                <div className="col-12 py-5">
                    {(loading) ?
                        <div className="text-center">
                            <div className="spinner-border text-warning" role="status">
                                <span className="visually-hidden">Loading...</span>
                            </div>
                            <p className="text-warning mt-2">กำลังโหลด...</p>
                        </div> :
                        <p className="text-muted text-center">ยังไม่มีข้อมูล</p>
                    }
                </div> :
                list.map((item, i) => {
                    return (
                        <div key={i} className="col-12 col-md-6 col-lg-4 col-xl-3">
                            <div className="card">
                                <div className="thumbnail-card card-img-top">
                                    <img src={item.image} className="thumbnail-img" alt=""/>
                                </div>
                                <div className="card-body">
                                    <div className="row g-3">
                                        {(item.url) ?
                                            <div className="col-4">
                                                <a className="btn btn-egg w-100" href={item.url} target="_blank" rel="noreferrer" data-tip={item.url}><i className="bi bi-link-45deg me-2"></i>URL</a>
                                                <ReactTooltip place="top" type="dark" effect="solid"/>
                                            </div> : null
                                        }
                                        <div className="col-4">
                                            <button type="button" className="btn btn-info-old w-100" onClick={() => props.handleUpdate(item)}><i className="bi bi-pencil-square me-2"></i>แก้ไข</button>
                                        </div>
                                        <div className="col-4">
                                            <button type="button" className="btn btn-danger w-100" onClick={() => props.handleRemove(item._id)}><i className="bi bi-trash-fill me-2"></i>ลบ</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                })}
        </div>
    )
}

export default withRouter(CardList)