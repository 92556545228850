import React from 'react'
import { Pie } from 'react-chartjs-2'
import 'chartjs-plugin-annotation'
import InputDateRange from "../../components/input-date-range"
import moment from 'moment'
import Format from "../../functions/format"

function PieChart(props) {
    let total = (props.data.length > 0) ? props.data.reduce((accumulator, currentValue) => accumulator + currentValue) : 0
    let labels = props.data.map((value, i) => {
        return props.labels[i] + ` ${Format.price(value)} (${Format.price((total === 0 ? 0 : (value / total) * 100), 1)}%)`
    })

    return (
        <div className={props.parentClassName}>
            <div className="card h-100">
                <div className="card-body text-center">
                    <p className="text-potion mb-2 text-center">{props.title}</p>
                    {(props.displayDate) ?
                        <div className="row g-3 justify-content-center mb-2">
                            <div className="col-12 col-md-10 col-lg-8 col-xl-6">
                                <InputDateRange period={props.period} onChange={props.handleChange} />
                            </div>
                        </div> : null
                    }
                    <Pie
                        data={{
                            datasets: [{
                                data: props.data,
                                backgroundColor: props.backgroundColor
                            }],
                            labels: labels
                        }}
                        width={props.width}
                        height={props.height}
                        options={{
                            responsive: true,
                            legend: {
                                position: 'top'
                            },
                            animation: {
                                animateScale: true,
                                animateRotate: true
                            },
                            aspectRatio: 2
                        }}
                    />
                </div>
            </div>
        </div>
    )
}

PieChart.defaultProps = {
    title: "title",
    backgroundColor: ["#FFC107", "#212529", "#82868B"],
    labels: [],
    data: [],
    width: 100,
    height: 100,
    displayDate: false,
    period: {
        startDate: "1970-01-01",
        endDate: moment().format('YYYY-MM-DD')
    }
}

export default PieChart