import React, { useState, useEffect, useCallback } from "react"
import ReactDOM from 'react-dom'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import MainLayout from "../../components/main-layout"
import Title from "../../components/title"
import CardList from "./card-list"
import ActionGroup from "./action-group"
import Alert from "../../functions/alert"
import AlertModal from "../../components/alert-modal"
import CONS from "../../constants"
import Log from "../../functions/log"

function AdminApp(props) {
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState([])

    const handleRemove = (id) => {
        ReactDOM.render(
            <AlertModal
                title="ยืนยันการลบหรือไม่ ?"
                btnSubmitClass="btn btn-danger"
                callBack={() => {
                    Log.save(CONS.API_ADMIN + `/user`, { _id: id })
                    axios.delete(CONS.API_ADMIN + `/user`, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
                        },
                        data: {
                            _id: id
                        }
                    }).then((response) => {
                        if (response.data.status === 200) {
                            Alert.showMsg("บันทึกเรียบร้อย")
                            loadList()
                        } else {
                            Alert.showMsg("เกิดข้อผิดพลาด บันทึกไม่สำเร็จ", "danger")
                        }
                    }).catch((error) => {
                        if (error.response !== undefined) {
                            if (error.response.status === 401) {
                                localStorage.removeItem('token')
                                localStorage.removeItem('role')
                                localStorage.removeItem('name')
                                props.history.push(CONS.URL_LINK + '/login?auth=false')
                            }
                        }
                    })
                }}
            />, document.getElementById('modal-root')
        )
    }

    const loadList = useCallback(() => {
        setLoading(true)
        Log.save(CONS.API_ADMIN + `/user`, {})
        axios.get(CONS.API_ADMIN + `/user`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                setList(response.data.datas)
            }
            setLoading(false)
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            } else {
                setLoading(false)
            }
        })
    }, [props.history])

    useEffect(() => {
        loadList()
    }, [loadList])

    return (
        <MainLayout active="admin" authorized="admin">
            <Title title="ผู้ดูแลระบบ" />
            <ActionGroup />
            <CardList loading={loading} lists={list} handleRemove={handleRemove} />
        </MainLayout>
    )
}

export default withRouter(AdminApp)