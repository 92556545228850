import React, { useState, useEffect, useCallback } from "react"
import ReactDOM from 'react-dom'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import MainLayout from "../../components/main-layout"
import Title from "../../components/title"
import AlertModal from "../../components/alert-modal"
import Alert from "../../functions/alert"
import ActionGroup from "./action-group"
import CardList from "./card-list"
import Modal from "./modal"
import CONS from "../../constants"
import Format from '../../functions/format'
import Log from "../../functions/log"

function CourseApp(props) {
    const [loading, setLoading] = useState(true)
    const [count, setCount] = useState(0)
    const [list, setList] = useState([])
    const [page, setPage] = useState({
        size: 100,
        index: 1
    })

    async function handleChangeActive(index) {
        switch (index) {
            case "-": index = page.index - 1; break;
            case "+": index = page.index + 1; break;
            default: break;
        }
        setPage({ ...page, index: index })
    }

    const handleCreate = () => {
        ReactDOM.render(
            <Modal
                callBack={() => {
                    loadList()
                }}
            />, document.getElementById('modal-root')
        )
    }

    const handleUpdate = (data) => {
        ReactDOM.render(
            <Modal
                title="แก้ไข"
                data={{ ...data, date: Format.convertToISOToDateString(data.date) }}
                callBack={() => {
                    loadList()
                }}
            />, document.getElementById('modal-root')
        )
    }

    const handleRemove = (id) => {
        ReactDOM.render(
            <AlertModal
                title="ยืนยันการลมหรือไม่ ?"
                btnSubmitClass="btn btn-danger"
                callBack={() => {
                    Log.save(CONS.API_CLASS, { id: id })
                    axios.delete(CONS.API_CLASS, {
                        headers: {
                            'Content-Type': 'application/json',
                            'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
                        },
                        data: {
                            id: id
                        }
                    }).then((response) => {
                        if (response.data.status === 200) {
                            Alert.showMsg("บันทึกเรียบร้อย")
                            var maxPage = ((count - 1) <= 0) ? 1 : Math.ceil((count - 1) / page.size)
                            var index = (page.index > maxPage) ? maxPage : page.index
                            setPage({ ...page, index: index })
                        } else {
                            Alert.showMsg("เกิดข้อผิดพลาด บันทึกไม่สำเร็จ", "danger")
                        }
                    }).catch((error) => {
                        if (error.response !== undefined) {
                            if (error.response.status === 401) {
                                localStorage.removeItem('token')
                                localStorage.removeItem('role')
                                localStorage.removeItem('name')
                                props.history.push(CONS.URL_LINK + '/login?auth=false')
                            }
                        }
                    })
                }}
            />, document.getElementById('modal-root')
        )
    }

    const loadList = useCallback(() => {
        setLoading(true)
        Log.save(CONS.API_CLASS + `?page_size=${page.size}&page_index=${page.index}`, {})
        axios.get(CONS.API_CLASS + `?page_size=${page.size}&page_index=${page.index}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                setCount(response.data.element_total)
                setList(response.data.elements)
            }
            setLoading(false)
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            } else {
                setLoading(false)
            }
        })
    }, [props.history, page])

    useEffect(() => {
        loadList()
    }, [loadList])

    return (
        <MainLayout active="course" authorized="course">
            <Title title="คอร์สอบรม" />
            <ActionGroup handleCreate={handleCreate} />
            <CardList loading={loading} count={count} lists={list} page={page} handleChangeActive={handleChangeActive} handleUpdate={handleUpdate} handleRemove={handleRemove} />
        </MainLayout>
    )
}

export default withRouter(CourseApp)