import React, { useEffect } from 'react'
import ReactDOM from 'react-dom'

function AlertModal(props) {
    const handleSubmit = () => {
        var modal = document.getElementById(props.id)
        modal.classList.remove(`show`)
        var backdrop = document.getElementById(`modal-backdrop`)
        backdrop.classList.remove(`show`)
        document.body.classList.remove(`modal-open`)
        document.body.style.overflow = null
        document.body.style.paddingRight = null
        setTimeout(() => {
            if (props.callBack !== undefined) props.callBack()
            ReactDOM.unmountComponentAtNode(document.getElementById('modal-root'))
            backdrop.parentNode.removeChild(backdrop)
        }, 300)
    }

    const handleClose = () => {
        var modal = document.getElementById(props.id)
        modal.classList.remove(`show`)
        var backdrop = document.getElementById(`modal-backdrop`)
        backdrop.classList.remove(`show`)
        document.body.classList.remove(`modal-open`)
        document.body.style.overflow = null
        document.body.style.paddingRight = null
        setTimeout(() => {
            ReactDOM.unmountComponentAtNode(document.getElementById('modal-root'))
            backdrop.parentNode.removeChild(backdrop)
        }, 300)
    }

    useEffect(() => {
        document.body.className = "modal-open"
        document.body.style.overflow = "hidden"
        document.body.style.paddingRight = "0px"
        var check = (document.getElementById("modal-backdrop") === null) ? true : false
        if (check) {
            var backdrop = document.createElement("div")
            backdrop.id = "modal-backdrop"
            backdrop.className = "modal-backdrop fade"
            document.body.appendChild(backdrop)
        }
        document.getElementById(props.id).classList.add("d-block")
        setTimeout(() => {
            document.getElementById(props.id).classList.add("show")
            if (check) {
                backdrop.classList.add("show")
            }
        }, 200)
    }, [props])

    return (
        <div className="modal fade" id={props.id} tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content">
                    <div className="modal-header border-bottom-0 bg-warning">
                        <h6 className="modal-title" >
                            {props.title}
                        </h6>
                        <button type="button" className="btn-close" onClick={handleClose}></button>
                    </div>
                    {props.showDescription ?
                        <div className="modal-body border-bottom border-top">
                            <div className="mb-0 text-muted">{props.description}</div>
                        </div> : null
                    }
                    <div className="modal-footer border-top-0">
                        {props.btnCancel ? <button type="button" className={props.btnCancelClass} onClick={handleClose}>{props.btnCancelText}</button> : null}
                        <button type="button" className={props.btnSubmitClass} onClick={handleSubmit}>{props.btnSubmitText}</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

AlertModal.defaultProps = {
    id: "alertModal",
    title: "แจ้งเตือน",
    showDescription: false,
    description: "",
    btnSubmitClass: "btn btn-success",
    btnSubmitText: "ยืนยัน",
    btnCancel: false,
    btnCancelClass: "btn btn-secondary",
    btnCancelText: "ยกเลิก"
}

export default AlertModal