var Alert = {
    showMsg(msg = "", style = "success", color = "white", timeOut = 3000) {
        //Create and show alert
        var div = document.createElement('div')
        div.id = "alertShowMsg";
        div.className = `toast align-items-center text-${color} bg-${style} border-0 fade show`
        div.style.position = "fixed"
        div.style.top = "20px"
        div.style.zIndex = "1060"
        div.style.left = "50%"
        div.style.transform = "translate(-50%)"
        div.style.minWidth = (window.screen.availWidth < 576) ? "90%" : "600px"
        div.style.opacity = 1
        div.innerHTML = `<div class="d-flex"><div class="toast-body">${msg}</div><button type="button" class="btn-close btn-close-${style} me-2 m-auto" data-bs-dismiss="toast" aria-label="Close"></button></div>`
        document.body.appendChild(div)

        //Timeout remove and animation
        setTimeout(function () {
            var fadeOut = setInterval(function () {
                if (div.style.opacity > 0) {
                    div.style.opacity -= 0.1
                } else {
                    if (document.getElementById('alertShowMsg') != null) {
                        div.parentNode.removeChild(div)
                    }
                    clearInterval(fadeOut)
                }
            }, 100)
        }, timeOut)
    },

    loadingScreen(title = "กำลังส่งข้อมูล...") {
        var loadingScreen = document.getElementById(`loadingScreen`)
        if (loadingScreen !== null) {
            document.getElementById("spinner-back").className = ""
            document.getElementById("spinner-front").className = ""
            setTimeout(() => {
                loadingScreen.parentNode.removeChild(loadingScreen)
                document.body.classList.remove(`modal-open`)
                document.body.style.overflow = null
                document.body.style.paddingRight = null
            }, 500)
        } else {
            var div = document.createElement('div')
            div.id = "loadingScreen"
            div.innerHTML = `<div id="spinner-back" style=" position: fixed;width: 100vw;transition: all 0.5s;visibility: hidden;opacity: 0;z-index: 1060;height: 100vh; background: rgba(0, 0, 0, 0.7);top: 0;"></div>
                            <div id="spinner-front" style=" position: fixed;width: 100vw;transition: all 0.5s;visibility: hidden;opacity: 0;z-index: 1061;color: #fff;text-align: center;margin-top: 50vh;transform: translateY(-50%);top: 0;">
                            <div class="spinner-border text-light" role="status"><span class="visually-hidden">Loading...</span></div><p>${title}</p></div>`
            document.body.appendChild(div)
            document.body.className = "modal-open"
            document.body.style.overflow = "hidden"
            document.body.style.paddingRight = "0px"
            setTimeout(() => {
                document.getElementById("spinner-back").className = "visible opacity-100"
                document.getElementById("spinner-front").className = "visible opacity-100"
            }, 200)
        }
    }
}

module.exports = Alert