const PERMISSION = [
    { code: "dashboard", title: "ภาพรวม", link: "/dashboard/main" },
    { code: "news", title: "ข่าว", link: "/news" },
    { code: "announcement", title: "ประกาศสำคัญ", link: "/announcement" },
    { code: "course", title: "คอร์สอบรบ", link: "/course" },
    { code: "whatsNew", title: "What's new", link: "/whatsNew" },
    { code: "blockUser", title: "Block User", link: "/blockUser" },
    { code: "consent", title: "Consent", link: "/consent" },
    { code: "forceUpdate", title: "Force Update", link: "/force-update" },
    { code: "admin", title: "ผู้ดูแลระบบ", link: "/admin" },
    { code: "etc", title: "เพิ่มเติม", link: null },
]
export default PERMISSION