import React from 'react'
import { withRouter } from "react-router-dom"
import ReactTooltip from "react-tooltip"

function DataCard(props) {
    return (
        <div className={props.parentClassName}>
            <div className="card h-100">
                <div className="card-body text-center">
                    <p className="text-potion mb-2">{props.title} {(props.tooltip) ? <i className="bi bi-exclamation-circle-fill" data-for="main" data-tip={props.tooltip}></i> : null}</p>
                    {(props.tooltip) ? <ReactTooltip id="main" place="right" effect="solid" multiline={true} /> : null}
                    <h3>{props.data}</h3>
                    <h4 className="mb-0">{props.unit}</h4>
                </div>
            </div>
        </div>
    )
}

DataCard.defaultProps = {
    parentClassName: "col",
    title: "title",
    data: "data",
    unit: "unit",
    tooltip: "",
}

export default withRouter(DataCard)