import axios from "axios"
import CONS from "../constants"

const Log = {
    save: async (api = "", body = {}) => {
        let data = {
            log: JSON.stringify({
                api: api,
                body: body
            }),
            ip: localStorage.getItem('ipv4')
        }
        const res = await axios.put(CONS.API_ADMIN + "/log", data, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).catch((error) => {
            console.log("write log error!")
        })
        return res
    }
}

export default Log
