import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import Format from "../../functions/format"

function CardList(props) {
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState([])

    useEffect(() => {
        setLoading(props.loading)
    }, [props.loading])

    useEffect(() => {
        setList(props.lists)
    }, [props.lists])

    return (
        <div className="card">
            <div className="card-body p-3 p-md-4">
                <div className="row mb-3 gy-3 align-item-center">
                    <div className="col-12 col-md">
                        <p className="mb-0">ทั้งหมด {(loading) ? "..." : Format.price(list.length)} เวอร์ชั่น</p>
                    </div>
                </div>
                <div className="table-responsive mb-3">
                    <table className="table table-striped">
                        <thead className="text-nowrap">
                            <tr>
                                <th scope="col">เวอร์ชั่น</th>
                                <th scope="col">ไฟล์แนบ</th>
                            </tr>
                        </thead>
                        {(loading || list.length === 0) ?
                            <tbody>
                                <tr>
                                    <td colSpan={2}>
                                        {(loading) ?
                                            <div className="text-center">
                                                <div className="spinner-border text-warning" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div> :
                                            <p className="text-muted text-center mb-0">ยังไม่มีข้อมูล</p>
                                        }
                                    </td>
                                </tr>
                            </tbody> :
                            <tbody>
                                {list.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td>{item.v} {(i === 0) ? <span className="badge rounded-pill bg-primary ms-3">Active</span> : null}</td>
                                            <td><div className="pointer d-inline-block" onClick={() => window.open(item.url)}><u>{item.url}</u></div></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        }
                    </table>
                </div>

            </div>
        </div>
    )
}

export default withRouter(CardList)