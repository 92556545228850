import React, { useState } from "react"
import { withRouter } from "react-router-dom"
import MainLayout from "../../components/main-layout"
import Title from "../../components/title"
import Dataset from "../../functions/dataset"
import moment from 'moment'
import axios from 'axios'
import CONS from "../../constants"
import Format from "../../functions/format"
// import Log from "../../functions/log"

function ExportLogApp(props) {
    // const [loading, setLoading] = useState(false)
    const [data, setData] = useState({
        type: "admin",
        month: moment().format('MM'),
        year: moment().format('YYYY'),
    })

    const handleChange = (e) => {
        data[e.target.name] = (e.target.validity === undefined || !e.target.validity.patternMismatch ? e.target.value : data[e.target.name])
        setData({ ...data })
    }

    const handleDownload = () => {
        if (data.type === "admin") {
            let startDate = Format.convertDateToISOString(`${data.year}-${data.month}-01`)
            let endDate = Format.convertDateToISOString(moment(`${data.year}-${data.month}-01`).endOf('month').format('YYYY-MM-DD'), "23.59.59")
            axios.get(CONS.API_ADMIN + `/log/export?startDate=${startDate}&endDate=${endDate}`, {
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
                }
            }).then((response) => {
                var blob = new Blob([response.data], { type: 'text/csv;charset=utf-8;' })
                var link = document.createElement("a")
                var url = URL.createObjectURL(blob)
                link.setAttribute("href", url)
                link.setAttribute("download", `${data.year}-${data.month}.csv`)
                link.style.visibility = 'hidden'
                document.body.appendChild(link)
                link.click()
                document.body.removeChild(link)
            }).catch((error) => {
                if (error.response !== undefined) {
                    if (error.response.status === 401) {
                        localStorage.removeItem('token')
                        localStorage.removeItem('role')
                        localStorage.removeItem('name')
                        props.history.push(CONS.URL_LINK + '/login?auth=false')
                    }
                }
            })
        }
    }

    return (
        <MainLayout active="etc" authorized="etc">
            <Title title="Log Export" />
            <div className="card mb-3">
                <div className="card-body p-3 p-md-4">
                    <div className="row g-4">
                        <div className="col-12">
                            <div className="form-check form-check-inline me-5">
                                <input className="form-check-input" type="radio" name="type" id="typeUser" value="user" checked={(data.type === "user")} onChange={handleChange} disabled />
                                <label className="form-check-label" htmlFor="typeUser">User Log</label>
                            </div>
                            <div className="form-check form-check-inline">
                                <input className="form-check-input" type="radio" name="type" id="typeAdmin" value="admin" checked={(data.type === "admin")} onChange={handleChange} />
                                <label className="form-check-label" htmlFor="typeAdmin">Admin Log</label>
                            </div>
                        </div>
                        <div className="col-12 col-md-3 col-lg-2">
                            <label className="form-label">ปี</label>
                            <select className="form-control" value={data.year} name="year" onChange={handleChange}>
                                {Dataset.yearList().map((item, i) => {
                                    return <option value={item.value} key={i}>{item.label}</option>
                                })}
                            </select>
                        </div>
                        <div className="col-12 col-md-3 col-lg-2">
                            <label className="form-label">เดือน</label>
                            <select className="form-control" value={data.month} name="month" onChange={handleChange}>
                                {Dataset.arrayMonth().map((item, i) => {
                                    return <option value={item.value} key={i}>{item.label}</option>
                                })}
                            </select>
                        </div>
                        <div className="col-12">
                            <button type="button" className="btn btn-success" onClick={handleDownload}><i className="bi bi-file-earmark-spreadsheet-fill me-2"></i>Export Excel</button>
                        </div>
                    </div>
                </div>
            </div>

        </MainLayout>
    )
}

export default withRouter(ExportLogApp)