import React, { useState, useEffect, useCallback } from "react"
import ReactDOM from 'react-dom'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import MainLayout from "../../components/main-layout"
import Title from "../../components/title"
import ActionGroup from "./action-group"
import CardList from "./card-list"
import Modal from "./modal"
import CONS from "../../constants"
import Log from "../../functions/log"

function AnnouncementApp(props) {
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState([])

    const handleUpdate = (data) => {
        ReactDOM.render(
            <Modal
                data={{ id: data._id, file: null, src: "", image: data.image, url: data.url, }}
                callBack={() => {
                    loadList()
                }}
            />, document.getElementById('modal-root')
        )
    }

    const loadList = useCallback(() => {
        Log.save(CONS.API_NEWS + "?important=true", {})
        axios.get(CONS.API_NEWS + "?important=true", {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                setList(response.data.news)
            }
            setLoading(false)
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            } else {
                setLoading(false)
            }
        })
    }, [props.history])

    useEffect(() => {
        loadList()
    }, [loadList])

    return (
        <MainLayout active="announcement" authorized="announcement">
            <Title title="ประกาศสำคัญ" />
            <ActionGroup />
            <CardList loading={loading} lists={list} handleUpdate={handleUpdate} />
        </MainLayout>
    )
}

export default withRouter(AnnouncementApp)