var Valid = {
    form: (formId) => {
        var count = 0
        var elements = document.getElementById(formId).querySelectorAll("[required]")

        Valid.clear(formId)

        for (var i = 0, len = elements.length; i < len; ++i) {
            if (elements[i].value !== '') continue;
            if (elements[i].dataset.text !== "no") {
                var span = document.createElement('span'),
                    msg = elements[i].getAttribute('data-msg')
                if (msg == null) msg = 'กรุณาป้อนข้อมูล'
                span.className = 'invalid-feedback'
                span.id = "required-" + elements[i].name
                span.innerHTML = msg
                elements[i].parentElement.appendChild(span)
            }
            elements[i].classList.add("is-invalid")
            count++
        }

        var results = (count > 0) ? false : true

        return results
    },

    clear: (formId = null) => {
        if (formId != null) {
            var elements = document.getElementById(formId).querySelectorAll("[required]")
            for (var s = 0, lens = elements.length; s < lens; ++s) {
                elements[s].classList.remove("is-invalid")
            }
        }

        var block = document.getElementsByClassName("invalid-feedback")
        for (var i = 0, len = block.length; i < len; i++) {
            block[0].remove()
        }
    }
}

module.exports = Valid
