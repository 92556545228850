import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import Format from "../../functions/format"

function CardList(props) {
    const [loading, setLoading] = useState(true)
    const [count, setCount] = useState(0)
    const [list, setList] = useState([])
    const [page, setPage] = useState({
        size: 1,
        index: 1
    })
    const [keyword, setKeyword] = useState(props?.filter?.keyword ?? '')

    useEffect(() => {
        setLoading(props.loading)
    }, [props.loading])

    useEffect(() => {
        setCount(props.count)
    }, [props.count])

    useEffect(() => {
        setList(props.lists)
    }, [props.lists])

    useEffect(() => {
        setPage(props.page)
    }, [props.page])

    return (
        <div className="card">
            <div className="card-body p-3 p-md-4">
                <div className="row mb-3 gy-3 align-item-center">
                    <div className="col-12 col-md">
                        <span>ทั้งหมด {(loading) ? "..." : Format.price(count)} user</span>
                    </div>
                    <div className="col-12 col-md-6 col-lg-5 col-xl-4 text-end">
                        <div className="input-group">
                            <input type="text" className={"form-control" + (loading ? " placeholder" : "")} placeholder="ค้นหา" name="keyword" value={keyword}
                                onChange={(e) => {
                                    setKeyword(e.target.value)
                                }}
                                onKeyDown={e => {
                                    if (e.keyCode === 13) {
                                        e.preventDefault()
                                        props.loadList(props?.filter?.status ?? '', e.target.value)
                                    }
                                }}
                                readOnly={loading} />
                            {keyword && <span className="clear-block-user-search pointer" onClick={() => setKeyword('')}><i class="bi bi-x-circle-fill"></i></span>}
                            <span className="input-group-text bg-white pointer" onClick={() => props.loadList(props?.filter?.status ?? '', keyword)}><i className="bi bi-search"></i></span>
                        </div>
                    </div>
                    <div className="col-12 col-md-4 col-lg-3 col-xl-2 text-end">
                        <div className="dropdown">
                            <button className={`btn btn-light border-dark text-start ${(props?.filter?.status === '') ? '' : (props?.filter?.status === 'ปกติ') ? "text-success" : "text-danger"} dropdown-toggle toggle-end w-100`} type="button" id={"block"} data-bs-toggle="dropdown" aria-expanded="false">
                                <i className={`bi ${(props?.filter?.status === '') ? '' : (props?.filter?.status === 'ปกติ') ? "bi-check-lg" : "bi-person-x-fill"} me-2`}></i>{(props?.filter?.status === '') ? 'ทั้งหมด' : (props?.filter?.status === 'ปกติ') ? "ปกติ" : "Block"}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby={"block"}>
                                <li><div className="dropdown-item pointer" onClick={() => props.loadList('', keyword)}>ทั้งหมด</div></li>
                                <li><div className="dropdown-item pointer text-success" onClick={() => props.loadList('ปกติ', keyword)}><i className="bi bi-check-lg me-2"></i>ปกติ</div></li>
                                <li><div className="dropdown-item pointer text-danger" onClick={() => props.loadList('block', keyword)}><i className="bi bi-person-x-fill me-2"></i>Block</div></li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead className="text-nowrap">
                            <tr>
                                <th scope="col">ชื่อ user</th>
                                <th scope="col">วันที่เริ่มใช้งาน</th>
                                <th scope="col" width="180px">สถานะ</th>
                            </tr>
                        </thead>
                        {(loading || list.length === 0) ?
                            <tbody>
                                <tr>
                                    <td colSpan={3}>
                                        {(loading) ?
                                            <div className="text-center">
                                                <div className="spinner-border text-warning" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div> :
                                            <p className="text-muted text-center mb-0">ยังไม่มีข้อมูล</p>
                                        }
                                    </td>
                                </tr>
                            </tbody> :
                            <tbody>
                                {list.map((item, i) => {
                                    return (
                                        <tr key={i}>
                                            <td className={(item.block) ? "text-danger" : ""}>{(item.name) ? item.name : "ไม่มีชื่อ"}</td>
                                            <td className={(item.block) ? "text-danger" : ""}>{Format.convertToISOToDateString(item.register, true)}</td>
                                            <td>
                                                <div className="dropdown">
                                                    <button className={`btn btn-light border-dark text-start ${(!item.block) ? "text-success" : "text-danger"} dropdown-toggle toggle-end w-100`} type="button" id={"block" + item.id} data-bs-toggle="dropdown" aria-expanded="false">
                                                        <i className={`bi ${(!item.block) ? "bi-check-lg" : "bi-person-x-fill"} me-2`}></i>{(!item.block) ? "ปกติ" : "Block"}
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby={"block" + item.id}>
                                                        <li><div className="dropdown-item pointer text-success" onClick={() => props.handleChangeBlock(i, false)}><i className="bi bi-check-lg me-2"></i>ปกติ</div></li>
                                                        <li><div className="dropdown-item pointer text-danger" onClick={() => props.handleChangeBlock(i, true)}><i className="bi bi-person-x-fill me-2"></i>Block</div></li>
                                                    </ul>
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                        }
                    </table>
                </div>
                <div className="mb-3">
                    <nav aria-label="Page navigation">
                        <ul className="pagination justify-content-center">
                            <li className={"page-item" + (page.index !== 1 ? "" : " disabled")}>
                                <span className="page-link pointer" onClick={() => props.handleChangeActive("-")}>{"<<"}</span>
                            </li>
                            {Format.genPagination(page.index, page.size, count).map((item, i) => {
                                return (
                                    <li className={"page-item" + (item === page.index ? " active" : "")} key={i}>
                                        <span className="page-link pointer" onClick={() => props.handleChangeActive(item)}>{item}</span>
                                    </li>
                                )
                            })}
                            <li className={"page-item" + (Math.ceil((count / page.size)) > page.index ? "" : " disabled")}>
                                <span className="page-link pointer" onClick={() => props.handleChangeActive("+")}>{">>"}</span>
                            </li>
                        </ul>
                    </nav>
                </div>

            </div>
        </div>
    )
}

export default withRouter(CardList)