import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import Format from "../../functions/format"

function CardList(props) {
    const [loading, setLoading] = useState(true)
    const [count, setCount] = useState(0)
    const [list, setList] = useState([])
    const [page, setPage] = useState({
        size: 100,
        index: 1
    })

    async function handleChangeActive(index) {
        switch (index) {
            case "-": index = page.index - 1; break;
            case "+": index = page.index + 1; break;
            default: break;
        }
        setPage({ ...page, index: index })
    }

    useEffect(() => {
        setLoading(props.loading)
    }, [props.loading])

    useEffect(() => {
        setCount(props.count)
    }, [props.count])

    useEffect(() => {
        setList(props.lists)
    }, [props.lists])

    return (
        <div className="card">
            <div className="card-body p-3 p-md-4">
                <p>ทั้งหมด {(loading) ? "..." : Format.price(count)} เวอร์ชั่น</p>
                <div className="table-responsive">
                    <table className="table table-striped">
                        <thead className="text-nowrap">
                            <tr>
                                <th scope="col">เวอร์ชั่น</th>
                                <th scope="col">ข้อความ</th>
                                <th scope="col" width="120px">ตัวเลือก</th>
                            </tr>
                        </thead>
                        {(loading || list.length === 0) ?
                            <tbody>
                                <tr>
                                    <td colSpan={3}>
                                        {(loading) ?
                                            <div className="text-center">
                                                <div className="spinner-border text-warning" role="status">
                                                    <span className="visually-hidden">Loading...</span>
                                                </div>
                                            </div> :
                                            <p className="text-muted text-center mb-0">ยังไม่มีข้อมูล</p>
                                        }
                                    </td>
                                </tr>
                            </tbody> :
                            <tbody>
                                {list.map((item, i) => {
                                    if (Math.ceil((i + 1) / page.size) === page.index) {
                                        if((i === 2 && page.index === 1)){
                                            console.log(i, page.index)
                                        }
                                        return (
                                            <tr key={i}>
                                                <td>{item.title} {(i === 2 && page.index === 1) ? <span className="badge rounded-pill bg-primary ms-3">Active</span> : null}</td>
                                                <td><span className="white-space-pre-wrap" dangerouslySetInnerHTML={{ __html: item.feature }}></span></td>
                                                <td >
                                                    <div className="text-nowrap">
                                                        <button type="button" className="btn btn-info-old me-2" onClick={() => props.handleUpdate(item)}><i className="bi bi-pencil-square me-2"></i>แก้ไข</button>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    } else {
                                        return null
                                    }
                                })}
                            </tbody>
                        }
                    </table>
                </div>
                <div className="mb-3">
                    <nav aria-label="Page navigation">
                        <ul className="pagination justify-content-center">
                            <li className={"page-item" + (page.index !== 1 ? "" : " disabled")}>
                                <span className="page-link pointer" onClick={() => handleChangeActive("-")}>{"<<"}</span>
                            </li>
                            {Format.genPagination(page.index, page.size, count).map((item, i) => {
                                return (
                                    <li className={"page-item" + (item === page.index ? " active" : "")} key={i}>
                                        <span className="page-link pointer" onClick={() => handleChangeActive(item)}>{item}</span>
                                    </li>
                                )
                            })}
                            <li className={"page-item" + (Math.ceil((count / page.size)) > page.index ? "" : " disabled")}>
                                <span className="page-link pointer" onClick={() => handleChangeActive("+")}>{">>"}</span>
                            </li>
                        </ul>
                    </nav>
                </div>

            </div>
        </div>
    )
}

export default withRouter(CardList)