import React, { useState, useEffect } from "react"
import { withRouter } from "react-router-dom"
import axios from 'axios'
import CONS from "../../constants"
import Alert from "../../functions/alert"
import logo from "../../assets/ico_app.png"
import Valid from "../../functions/validation"
import Log from "../../functions/log"
import PERMISSION from "../../constants/permisstion"

function LoginApp(props) {
    const [attr, setAttr] = useState({
        show: false,
        isLogin: false
    })
    const [data, setData] = useState({
        username: "",
        password: ""
    })

    const handleChange = (e) => {
        setData({ ...data, [e.target.name]: e.target.value })
    }

    const handleLogin = () => {
        setAttr({ ...attr, isLogin: true })
        var valid = Valid.form("login-form")
        if (valid) {
            axios.post(CONS.API_LOGIN, data, {
                headers: {
                    'Content-Type': 'application/json',
                },
                auth: {
                    username: CONS.BASIC_USERNAME,
                    password: CONS.BASIC_PASSWORD
                }
            }).then((response) => {
                setAttr({ ...attr, isLogin: false })
                if (response.data.status === 200 || response.data.status === 205) {
                    if (response.data.token) {
                        if (response.data.changePassword) {
                            localStorage.setItem("token", response.data.token)
                            localStorage.setItem("role", JSON.stringify(response.data.role))
                            localStorage.setItem("name", response.data.name)
                            localStorage.setItem("oldPass", data.password)
                            Log.save(CONS.API_LOGIN, { ...data, password: "" })
                            props.history.push(CONS.URL_LINK + "/reset/password")
                        } else {
                            if (response.data.role.length > 0) {
                                localStorage.setItem("token", response.data.token)
                                localStorage.setItem("role", JSON.stringify(response.data.role))
                                localStorage.setItem("name", response.data.name)
                                Log.save(CONS.API_LOGIN, { ...data, password: "" })
                                for (const item of PERMISSION) {
                                    var check = response.data.role.includes(item.code)
                                    if (check) {
                                        var firstMenu = (item.code === "etc") ? '/export/log' : item.link
                                        break
                                    }
                                }
                                props.history.push(CONS.URL_LINK + firstMenu)
                            } else {
                                Alert.showMsg("ไม่สามารถเข้าใช้งานได้ เนื่องจากคุณไม่มีเมนูที่เข้าถึงได้", "danger")
                            }
                        }
                    } else {
                        Alert.showMsg("ชื่อบัญชีผู้ใช้หรือรหัสผ่านไม่ถูกต้อง", "danger")
                    }
                } else {
                    Alert.showMsg(response.data.message, "danger")
                }
            }).catch((error) => {
                setAttr({ ...attr, isLogin: false })
            })
        } else {
            Alert.showMsg("กรุณากรอกข้อมูลให้ครบถ้วน", "danger")
            setAttr({ ...attr, isLogin: false })
        }
    }

    const getData = async () => {
        try {
            const res = await axios.get('https://api.ipify.org?format=json')
            localStorage.setItem("ipv4", res.data.IPv4)
        } catch (error) {
            localStorage.setItem("ipv4", "127.0.0.1")
        }
    }

    useEffect(() => {
        getData()
        localStorage.removeItem("oldPass")
    }, [])

    return (
        <div className="container-fluid flex-grow-1">
            <div className="p-2 p-md-3 text-center">
                <div className="row justify-content-center">
                    <div className="col-auto">
                        <form id="login-form">
                            <div className="mb-3 mt-3">
                                <div className="row justify-content-center">
                                    <div className="col-4">
                                        <img src={logo} className="w-100 rounded-25" alt="" />
                                    </div>
                                </div>
                            </div>
                            <h3 className="fw-bold mb-4">ระบบบริหารจัดการข้อมูล</h3>
                            <div className="form-floating mb-3 text-start">
                                <input type="email" className="form-control" name="username" id="username" placeholder="ชื่อบัญชีผู้ใช้" value={data.username} onChange={handleChange} required />
                                <label className="w-webkit-fill-available" htmlFor="username">ชื่อบัญชีผู้ใช้</label>
                            </div>
                            <div className="input-group mb-3 text-start">
                                <div className="form-floating col">
                                    <input type={(attr.show) ? "text" : "password"} className="form-control rounded-left rounded-right-white" name="password" id="password" placeholder="รหัสผ่าน" value={data.password} onChange={handleChange} required data-text="no" />
                                    <label className="text-nowrap" htmlFor="password">รหัสผ่าน</label>
                                </div>
                                <span className="input-group-text pointer bg-white" onClick={() => setAttr({ ...attr, show: !attr.show })}>
                                    {(attr.show) ?
                                        <i className="bi bi-eye-fill"></i> :
                                        <i className="bi bi-eye-slash-fill"></i>
                                    }
                                </span>
                                <input type="hidden" name="password-hidden" value={data.password} onChange={() => { }} required />
                            </div>
                            <div className="d-grid">
                                <button type="button" className="btn btn-dark btn-lg" onClick={handleLogin} disabled={attr.isLogin}>เข้าสู่ระบบ</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(LoginApp)