import React, { Fragment, useState, useEffect } from "react"
import ReactDOM from 'react-dom'
import { withRouter, Link } from "react-router-dom"
import AlertModal from "./alert-modal"
// import logo from "../assets/ico_app.png"
import axios from 'axios'
import CONS from "../constants"
import PERMISSION from "../constants/permisstion"

function MainLayout(props) {
    const [name] = useState((localStorage.getItem('name')) ? localStorage.getItem('name') : "")
    const [permission] = useState((localStorage.getItem('role')) ? JSON.parse(localStorage.getItem('role')) : [])
    const [menu] = useState(PERMISSION)

    const handleLogout = () => {
        ReactDOM.render(
            <AlertModal
                title="ออกจากระบบ"
                btnSubmitClass="btn btn-danger"
                callBack={() => {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login')
                }}
            />, document.getElementById('modal-root')
        )
    }

    const checkMultipleAuthorized = (cPermission, cAuthorized) => {
        let data = false
        for (const per of cPermission) {
            for (const auth of cAuthorized) {
                if (per === auth) {
                    data = true
                }
            }
        }
        return data
    }

    const getData = async () => {
        const res = await axios.get('https://geolocation-db.com/json/')
        localStorage.setItem("ipv4", res.data.IPv4)
    }

    useEffect(() => {
        getData()
    }, [])

    return (
        <Fragment>
            <nav className="navbar fixed-top navbar-expand-lg py-0 navbar-light bg-yellow">
                <div className="container-fluid fw-bold">
                    {/* <div className="navbar-brand min-width-nav">
                        <img src={logo} alt="" width="35px" className="rounded-25" />
                    </div> */}
                    <button className="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav nav-main col pt-0 pt-lg-1 text-nowrap">
                            {menu.map((item, i) => {
                                if (permission.includes(item.code)) {
                                    if (item.code === "etc") {
                                        return (
                                            <li key={i} className="nav-item dropdown">
                                                <div className="nav-link dropdown-toggle pointer" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                                    <span className="me-2">เพิ่มเติม</span>
                                                </div>
                                                <ul className="dropdown-menu dropdown-menu-end mb-3">
                                                    <li><Link className="dropdown-item" to={CONS.URL_LINK + "/export/log"}>Log Export</Link></li>
                                                    <li><div className="dropdown-item pointer" onClick={() => (CONS.URL_CAMPAIGN ? window.open(CONS.URL_CAMPAIGN, "_blank") : null)}>Campaign <i className="bi bi-box-arrow-up-right"></i></div></li>
                                                    <li><div className="dropdown-item pointer" onClick={() => (CONS.URL_PUSH_NOTI ? window.open(CONS.URL_PUSH_NOTI, "_blank") : null)}>Push Notification <i className="bi bi-box-arrow-up-right"></i></div></li>
                                                </ul>
                                            </li>
                                        )
                                    } else {
                                        return (
                                            <li key={i} className="nav-item">
                                                {(item.link) ?
                                                    <Link className={"nav-link px-0 px-lg-3" + (props.active === item.code ? " active" : "")} to={CONS.URL_LINK + item.link}>{item.title}</Link> :
                                                    <div className={"nav-link px-0 px-lg-3" + (props.active === item.code ? " active" : "")}>{item.title}</div>
                                                }
                                            </li>
                                        )
                                    }
                                } else {
                                    return null
                                }
                            })}
                        </ul>
                        <div className="d-flex min-width-nav">
                            <ul className="navbar-nav me-auto w-100 justify-content-end">
                                <li className="nav-item dropdown">
                                    <div className="nav-link dropdown-toggle pointer" role="button" data-bs-toggle="dropdown" aria-expanded="false">
                                        <span className="me-2">{name}</span>
                                    </div>
                                    <ul className="dropdown-menu dropdown-menu-end mb-3">
                                        <li><Link className="dropdown-item pointer" to={CONS.URL_LINK + "/change/password"}>เปลี่ยนรหัสผ่าน</Link></li>
                                        <li><div className="dropdown-item pointer" onClick={handleLogout}>ออกจากระบบ</div></li>
                                    </ul>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </nav>
            {(props.multiAuthorized ? checkMultipleAuthorized(permission, props.authorized) : (permission.includes(props.authorized) || props.authorized === "free")) ?
                (props.pageNotFoun ?
                    <div className="row align-items-center justify-content-center flex-grow-1 p-top">
                        <div className="col-auto text-center">
                            <h1>
                                <i className="text-muted bi bi-clipboard-x"></i>
                            </h1>
                            <p className="text-muted">ไม่พบข้อมูล</p>
                        </div>
                    </div> :
                    <div id="body">
                        <div className="container-fluid py-3">
                            {props.children}
                        </div>
                    </div>
                ) :
                <div className="row align-items-center justify-content-center flex-grow-1 p-top">
                    <div className="col-auto text-center">
                        <h1>
                            <i className="text-muted bi bi-clipboard-x"></i>
                        </h1>
                        <p className="text-muted">Unauthorized</p>
                    </div>
                </div>
            }
        </Fragment>
    )
}

MainLayout.defaultProps = {
    active: "",
    authorized: "",
    pageNotFoun: false,
    multiAuthorized: false
}

export default withRouter(MainLayout)