import React, { useState, useEffect, forwardRef } from 'react'
import DatePicker from "react-datepicker"
import "react-datepicker/dist/react-datepicker.css"
import moment from 'moment'

function InputDateTime(props) {
    const [value, setValue] = useState((props.value !== "" && props.value !== undefined) ? moment(props.value, props.dateFormat.replaceAll("d", "D"))["_d"] : "")
    const [minDate, setMinDate] = useState((props.minDate !== "" && props.minDate !== undefined) ? moment(props.minDate, props.dateFormat.replaceAll("d", "D"))["_d"] : false)
    const [maxDate, setMaxDate] = useState((props.maxDate !== "" && props.maxDate !== undefined) ? moment(props.maxDate, props.dateFormat.replaceAll("d", "D"))["_d"] : false)

    async function handleChange(date) {
        setValue(date)
        if (props.onChange !== undefined) props.onChange({ target: { name: props.name, value: (date !== null ? moment(date).format(props.dateFormat.replaceAll("d", "D")) : "") } })
    }

    useEffect(() => {
        setValue((props.value !== "" && props.value !== undefined) ? moment(props.value, props.dateFormat.replaceAll("d", "D"))["_d"] : "")
    }, [props.value, props.dateFormat])

    useEffect(() => {
        setMinDate((props.minDate !== "" && props.minDate !== undefined) ? moment(props.minDate, props.dateFormat.replaceAll("d", "D"))["_d"] : false)
    }, [props.minDate, props.dateFormat])

    useEffect(() => {
        setMaxDate((props.maxDate !== "" && props.maxDate !== undefined) ? moment(props.maxDate, props.dateFormat.replaceAll("d", "D"))["_d"] : false)
    }, [props.maxDate, props.dateFormat])

    const ExampleCustomInput = forwardRef(({ value, onClick }, ref) => (
        <div className="input-group pointer" style={{ pointerEvents: (props.disabled ? "none" : "initial") }}>
            <span className="input-group-text bg-white" onClick={onClick}>
                <i className="bi bi-calendar3"></i>
            </span>
            <input type="text" className={props.className + " pointer"} name={props.name} value={value} onClick={onClick} onChange={() => null} readOnly={props.disabled} required={props.required}/>
        </div>
    ));

    return (
        <DatePicker
            className={props.className}
            showTimeSelect={props.showTimeSelect}
            timeFormat="HH:mm"
            timeIntervals={1}
            selected={value}
            minDate={minDate}
            maxDate={maxDate}
            onChange={handleChange}
            name={props.name}
            required={props.required}
            dateFormat={props.dateFormat}
            customInput={<ExampleCustomInput />}
        />
    )
}

InputDateTime.defaultProps = {
    className: "form-control",
    name: "date-picker",
    value: "",
    showTimeSelect: true,
    required: false,
    dateFormat: "dd/MM/yyyy HH:mm",
    minDate: "",
    maxDate: "",
    disabled: false
}

export default InputDateTime