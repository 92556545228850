import React, { useState, useEffect, useCallback } from "react"
import { withRouter } from "react-router-dom"
import axios from 'axios'
import Alert from "../../functions/alert"
import CONS from "../../constants"
import Log from "../../functions/log"

function ActionGroup(props) {
    const [publish, setPublish] = useState(true)

    const handleChangePublish = (newPublish) => {
        Log.save(CONS.API_CONFIG, { news: newPublish })
        axios.post(CONS.API_CONFIG, {
            news: newPublish
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                Alert.showMsg("บันทึกเรียบร้อย")
                setPublish(newPublish)
            } else {
                Alert.showMsg("เกิดข้อผิดพลาด บันทึกไม่สำเร็จ", "danger")
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }

    const loadConfig = useCallback(() => {
        Log.save(CONS.API_CONFIG, {})
        axios.get(CONS.API_CONFIG, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                setPublish(response.data.data.news)
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }, [props.history])

    useEffect(() => {
        loadConfig()
    }, [loadConfig])

    return (
        <div className="row g-3 mb-4">
            <div className="col-auto">
                <button type="button" className="btn btn-warning" onClick={props.handleCreate}><i className="bi bi-plus-circle-fill me-2"></i>เพิ่ม</button>
            </div>
            <div className="col text-end">
                <div className="row g-2 align-items-center">
                    <div className="col">แสดงผลบนแอป</div>
                    <div className="col-auto">
                        <div className="dropdown">
                            <button className={`btn btn-light border-dark ${(publish) ? "text-success" : "text-muted"} dropdown-toggle`} type="button" id="publish" data-bs-toggle="dropdown" aria-expanded="false">
                                <i className={`bi ${(publish) ? "bi-eye-fill" : "bi-eye-slash-fill"} me-2`}></i>{(publish) ? "เผยแพร่" : "ซ่อน"}
                            </button>
                            <ul className="dropdown-menu" aria-labelledby="publish">
                                <li><div className="dropdown-item pointer text-success" onClick={() => handleChangePublish(true)}><i className="bi bi-eye-fill me-2"></i>เผยแพร่</div></li>
                                <li><div className="dropdown-item pointer text-muted" onClick={() => handleChangePublish(false)}><i className="bi bi-eye-slash-fill me-2"></i>ซ่อน</div></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default withRouter(ActionGroup)