import React, { useState } from 'react'
import DateRangePicker from 'react-bootstrap-daterangepicker'
import 'bootstrap-daterangepicker/daterangepicker.css'
import moment from 'moment'

function InputDateRange2(props) {
    var label = moment(props.period.startDate, "YYYY-MM-DD").format('DD/MM/YYYY') + ' - ' + moment(props.period.endDate, "YYYY-MM-DD").format('DD/MM/YYYY')
    if (props.period.startDate === moment().format('YYYY-MM-DD') && props.period.endDate === moment().format('YYYY-MM-DD')) {
        label = "วันนี้"
    } else if (props.period.startDate === moment().subtract(6, 'days').format('YYYY-MM-DD') && props.period.endDate === moment().format('YYYY-MM-DD')) {
        label = "7 วันล่าสุด"
    } else if (props.period.startDate === moment().subtract(29, 'days').format('YYYY-MM-DD') && props.period.endDate === moment().format('YYYY-MM-DD')) {
        label = "30 วันล่าสุด"
    } else if (props.period.startDate === moment().startOf('month').format('YYYY-MM-DD') && props.period.endDate === moment().endOf('month').format('YYYY-MM-DD')) {
        label = "เดือนนี้"
    }

    const [labelDate, setLabelDate] = useState(label)
    const [period, setPeriod] = useState(props.period)

    const handleCallback = (start, end, label) => {
        setLabelDate((label === "กำหนดเอง") ? start.format('DD/MM/YYYY') + ' - ' + end.format('DD/MM/YYYY') : label)
        setPeriod({
            startDate: start.format('YYYY-MM-DD'),
            endDate: end.format('YYYY-MM-DD')
        })
        if (props.onChange !== undefined) props.onChange(start.format('YYYY-MM-DD'), end.format('YYYY-MM-DD'))
    }

    return (
        <DateRangePicker
            onCallback={handleCallback}
            initialSettings={{
                linkedCalendars: false,
                showDropdowns: true,
                startDate: moment(period.startDate, "YYYY-MM-DD"),
                endDate: moment(period.endDate, "YYYY-MM-DD"),
                minDate: moment(props.minDate, "YYYY-MM-DD"),
                maxSpan: {
                    days: 60
                },
                locale: {
                    "format": "DD/MM/YYYY",
                    "separator": " - ",
                    "applyLabel": "ยืนยัน",
                    "cancelLabel": "ยกเลิก",
                    "fromLabel": "ตั้งแต่",
                    "toLabel": "จนถึง",
                    "customRangeLabel": "กำหนดเอง",
                    "weekLabel": "สัปดาห์",
                    "daysOfWeek": ["อา.", "จ.", "อ.", "พ.", "พฤ.", "ศ.", "ส."],
                    "monthNames": ["ม.ค.", "ก.พ.", "มี.ค.", "เม.ย.", "พ.ค.", "มิ.ย.", "ก.ค.", "ส.ค.", "ก.ย.", "ต.ค.", "พ.ย.", "ธ.ค."],
                    "firstDay": 1
                },
                ranges: (props.allDate ? {
                    'วันนี้': [moment(), moment()],
                    '7 วันล่าสุด': [moment().subtract(6, 'days'), moment()],
                    '30 วันล่าสุด': [moment().subtract(29, 'days'), moment()],
                    'เดือนนี้': [moment().startOf('month'), moment().endOf('month')]
                } : {
                    'วันนี้': [moment(), moment()],
                    '7 วันล่าสุด': [moment().subtract(6, 'days'), moment()],
                    '30 วันล่าสุด': [moment().subtract(29, 'days'), moment()],
                    'เดือนนี้': [moment().startOf('month'), moment().endOf('month')]
                })
            }}>
            <div className="input-group pointer" style={{ pointerEvents: (props.disabled ? "none" : "initial") }}>
                <span className="input-group-text">
                    <i className="bi bi-calendar3"></i>
                </span>
                <input type="text" className={props.className + " pointer"} id={props.id} name={props.name} value={labelDate} onChange={() => null} readOnly={props.disabled} />
            </div>
        </DateRangePicker >
    )
}

InputDateRange2.defaultProps = {
    id: "date_range",
    name: "date_range",
    minDate: "2019-11-13",
    period: {
        startDate: moment().format('YYYY-MM-DD'),
        endDate: moment().format('YYYY-MM-DD')
    },
    className: "form-control",
    allDate: true,
    disabled: false
}

export default InputDateRange2