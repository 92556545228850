import React, { useState, useEffect, useCallback } from "react"
import { withRouter } from "react-router-dom"
import axios from 'axios'
import MainLayout from "../../components/main-layout"
import Title from "../../components/title"
import CardList from "./card-list"
import Alert from "../../functions/alert"
import CONS from "../../constants"
import Log from "../../functions/log"

function BlockUserApp(props) {
    const [loading, setLoading] = useState(true)
    const [count, setCount] = useState(0)
    const [list, setList] = useState([])
    const [page, setPage] = useState({
        size: 100,
        index: 1
    })
    const [filter, setFilter] = useState({
        status: '',
        keyword: '',
    })

    async function handleChangeActive(index) {
        switch (index) {
            case "-": index = page.index - 1; break;
            case "+": index = page.index + 1; break;
            default: break;
        }
        setPage({ ...page, index: index })
    }

    const handleChangeBlock = (index, block) => {
        Log.save(CONS.API_USER, {
            _id: list[index].id,
            block: block
        })
        axios.post(CONS.API_USER, {
            _id: list[index].id,
            block: block
        }, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                Alert.showMsg("บันทึกเรียบร้อย")
                list[index].block = block
                setList([...list])
            } else {
                Alert.showMsg("เกิดข้อผิดพลาด บันทึกไม่สำเร็จ", "danger")
            }
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            }
        })
    }

    const loadList = useCallback((page, status, keyword) => {
        setLoading(true)
        Log.save(CONS.API_USER + `?page_size=${page.size}&page_index=${page.index}&type_user=${status ?? ''}&keyword=${keyword ?? ''}`, {})
        axios.get(CONS.API_USER + `?page_size=${page.size}&page_index=${page.index}&type_user=${status ?? ''}&keyword=${keyword ?? ''}`, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                setCount(response.data.element_total)
                setList(response.data.elements)
                setFilter({
                    status: status,
                    keyword: keyword,
                })
            }
            setLoading(false)
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            } else {
                setLoading(false)
            }
        })
    }, [props.history])

    useEffect(() => {
        loadList(page, '', '')
    }, [loadList, page])

    return (
        <MainLayout active="blockUser" authorized="blockUser">
            <Title title="Block User" />
            <CardList loading={loading} count={count} lists={list} page={page} filter={filter} handleChangeActive={handleChangeActive} handleChangeBlock={handleChangeBlock} loadList={(status, keyword) => loadList(page, status, keyword)} />
        </MainLayout>
    )
}

export default withRouter(BlockUserApp)