import React, { useState, useEffect, useCallback } from "react"
import ReactDOM from 'react-dom'
import { withRouter } from "react-router-dom"
import axios from 'axios'
import MainLayout from "../../components/main-layout"
import Title from "../../components/title"
import CardList from "./card-list"
import ActionGroup from "./action-group"
import Modal from "./modal"
import CONS from "../../constants"
import Log from "../../functions/log"

function ConsentApp(props) {
    const [loading, setLoading] = useState(true)
    const [list, setList] = useState([])

    const handleCreate = () => {
        ReactDOM.render(
            <Modal
                last_version={(list.length > 0) ? list[0].v : ""}
                callBack={() => {
                    loadList()
                }}
            />, document.getElementById('modal-root')
        )
    }

    const loadList = useCallback(() => {
        setLoading(true)
        Log.save(CONS.API_TERM, {})
        axios.get(CONS.API_TERM, {
            headers: {
                'Content-Type': 'application/json',
                'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
            }
        }).then((response) => {
            if (response.data.status === 200) {
                setList(response.data.data)
            }
            setLoading(false)
        }).catch((error) => {
            if (error.response !== undefined) {
                if (error.response.status === 401) {
                    localStorage.removeItem('token')
                    localStorage.removeItem('role')
                    localStorage.removeItem('name')
                    props.history.push(CONS.URL_LINK + '/login?auth=false')
                }
            } else {
                setLoading(false)
            }
        })
    }, [props.history])

    useEffect(() => {
        loadList()
    }, [loadList])

    return (
        <MainLayout active="consent" authorized="consent">
            <Title title="Consent" />
            <ActionGroup handleCreate={handleCreate} />
            <CardList loading={loading} lists={list} />
        </MainLayout>
    )
}

export default withRouter(ConsentApp)