import React, { useState, useEffect } from 'react'
import ReactDOM from 'react-dom'
import axios from 'axios'
import Valid from '../../functions/validation'
import Alert from "../../functions/alert"
import CONS from "../../constants"
import Log from "../../functions/log"

function Modal(props) {
    const [data, setData] = useState(props.data)

    const handleSubmit = () => {
        var valid = Valid.form("form-data")
        if (valid) {
            if (data.id) {
                Log.save(CONS.API_WHATS_NEW, {...data})
                axios.post(CONS.API_WHATS_NEW, data, {
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': CONS.AUTH_PREFIX + localStorage.getItem('token')
                    }
                }).then((response) => {
                    if (response.data.status === 200) {
                        Alert.showMsg("บันทึกเรียบร้อย")
                        var modal = document.getElementById(props.id)
                        modal.classList.remove(`show`)
                        var backdrop = document.getElementById(`modal-backdrop`)
                        backdrop.classList.remove(`show`)
                        document.body.classList.remove(`modal-open`)
                        document.body.style.overflow = null
                        document.body.style.paddingRight = null
                        setTimeout(() => {
                            if (props.callBack !== undefined) props.callBack()
                            ReactDOM.unmountComponentAtNode(document.getElementById('modal-root'))
                            backdrop.parentNode.removeChild(backdrop)
                        }, 300)
                    } else {
                        Alert.showMsg("เกิดข้อผิดพลาด บันทึกไม่สำเร็จ", "danger")
                    }
                }).catch((error) => {
                    if (error.response !== undefined) {
                        if (error.response.status === 401) {
                            localStorage.removeItem('token')
                            localStorage.removeItem('role')
                            localStorage.removeItem('name')
                            props.history.push(CONS.URL_LINK + '/login?auth=false')
                        }
                    }
                })
            } else {
                Alert.showMsg("เกิดข้อผิดพลาด บันทึกไม่สำเร็จ", "danger")
            }
        } else {
            Alert.showMsg("กรุณากรอกข้อมูลให้ครบถ้วน", "danger")
        }
    }

    const handleClose = () => {
        var modal = document.getElementById(props.id)
        modal.classList.remove(`show`)
        var backdrop = document.getElementById(`modal-backdrop`)
        backdrop.classList.remove(`show`)
        document.body.classList.remove(`modal-open`)
        document.body.style.overflow = null
        document.body.style.paddingRight = null
        setTimeout(() => {
            ReactDOM.unmountComponentAtNode(document.getElementById('modal-root'))
            backdrop.parentNode.removeChild(backdrop)
        }, 300)
    }

    useEffect(() => {
        document.body.className = "modal-open"
        document.body.style.overflow = "hidden"
        document.body.style.paddingRight = "0px"
        var check = (document.getElementById("modal-backdrop") === null) ? true : false
        if (check) {
            var backdrop = document.createElement("div")
            backdrop.id = "modal-backdrop"
            backdrop.className = "modal-backdrop fade"
            document.body.appendChild(backdrop)
        }
        document.getElementById(props.id).classList.add("d-block")
        setTimeout(() => {
            document.getElementById(props.id).classList.add("show")
            if (check) {
                backdrop.classList.add("show")
            }
        }, 200)
    }, [props])

    return (
        <div className="modal fade" id={props.id} tabIndex="-1" role="dialog" aria-hidden="true">
            <div className="modal-dialog" role="document">
                <div className="modal-content min-height-90vh">
                    <div className="modal-header border-bottom-0 bg-warning">
                        <h6 className="modal-title">{props.title}</h6>
                        <button type="button" className="btn-close" onClick={handleClose}></button>
                    </div>
                    <div className="modal-body bg-sky border-bottom border-top">
                        <form id="form-data" autoComplete="off">
                            <div className="row g-4">
                                <div className="col-12">
                                    <label className="form-label">เวอร์ชั่น : {data.title}</label>
                                </div>
                                <div className="col-12">
                                    <label className="form-label">ข้อความ <b className="text-danger">*</b></label>
                                    <textarea className="form-control" name="feature" value={data.feature} onChange={(e) => setData({ ...data, [e.target.name]: e.target.value })} rows={12} required></textarea>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className="modal-footer border-top-0">
                        <button type="button" className="btn btn-secondary" onClick={handleClose}>ยกเลิก</button>
                        <button type="button" className="btn btn-success" onClick={handleSubmit}><i className="bi bi-check-lg me-2"></i>บันทึก</button>
                    </div>
                </div>
            </div>
        </div>
    )
}

Modal.defaultProps = {
    id: "whatsNewModal",
    title: "เพิ่ม",
    data: {
        id: "",
        title: "",
        feature: ""
    }
}

export default Modal