import React, { useState, Fragment } from 'react'
import { Pie } from 'react-chartjs-2'
import 'chartjs-plugin-annotation'
import Format from "../../functions/format"

function PieTwinChart(props) {
    let refs
    const [hidden, setHidden] = useState([])
    const handleHidden = (i) => {
        var meta = refs.getDatasetMeta(0)
        meta.data[i]["hidden"] = (meta.data[i]["hidden"]) ? false : true
        refs.update()
        if (hidden.includes(i)) {
            hidden.splice(hidden.indexOf(i), 1)
        } else {
            hidden.push(i)
        }
        setHidden([...hidden])
    }

    let total = (props.data.length > 0) ? props.data.reduce((accumulator, currentValue) => accumulator + currentValue) : 0
    let labels = props.data.map((value, i) => {
        return props.labels[i] + ` (${Format.price((total === 0 ? 0 : (value / total) * 100), 1)}%)`
    })

    return (
        <div className={props.parentClassName}>
            <div className="card h-100">
                <div className="card-body text-center">
                    {(!props.hideChart) ? <p className="text-potion mb-2 text-center">{props.title}</p> : null}
                    {(!props.hideType) ?
                        <div className="mb-3">
                            <div className="btn-group">
                                <label className={"btn px-3 " + (props.type === "value" ? "btn-dark" : "btn-outline-dark")} onClick={() => props.setType("value")}>มูลค่า</label>
                                <label className={"btn " + (props.type === "item" ? "btn-dark" : "btn-outline-dark")} onClick={() => props.setType("item")}>รายการ</label>
                            </div>
                        </div> : null
                    }
                    {(!props.hideChart) ?
                        <div className="mb-4">
                            {(labels.length) ?
                                <div className="row justify-content-center mb-2">
                                    {labels.map((item, i) => {
                                        return (
                                            <div className="col-auto cursor-default" key={i} onClick={() => handleHidden(i)}>
                                                <div className="labels-block" style={{ backgroundColor: props.backgroundColor[i] }}></div>
                                                <div className="d-inline-block text-muted small line-height-2 text-start text-nowrap">
                                                    <p className={"mb-0" + (hidden.includes(i) ? " text-decoration-line-through" : "")}>{item}</p>
                                                    <p className={"mb-0" + (hidden.includes(i) ? " text-decoration-line-through" : "")}>{Format.price(props.data[i])} {props.unit}</p>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </div> : null
                            }
                            <Pie
                                ref={(ref) => {
                                    if (ref) {
                                        refs = ref
                                    }
                                }}
                                data={{
                                    datasets: [{
                                        data: props.data,
                                        backgroundColor: props.backgroundColor
                                    }],
                                    labels: labels
                                }}
                                width={props.width}
                                height={props.height}
                                options={{
                                    responsive: true,
                                    legend: {
                                        position: 'top',
                                    },
                                    animation: {
                                        animateScale: true,
                                        animateRotate: true
                                    },
                                    aspectRatio: 2,
                                    plugins: {
                                        legend: {
                                            display: false,
                                            labels: {
                                                font: {
                                                    family: "Noto SansThai"
                                                }
                                            }
                                        }
                                    },
                                }}
                            />
                        </div> : null
                    }
                    {(!props.hideList) ?
                        <Fragment>
                            <p className="text-potion mb-3 text-center">{props.titleList}</p>
                            {(props.loading) ?
                                <div className="text-center py-3">
                                    <div className="spinner-border text-warning" role="status">
                                        <span className="visually-hidden">Loading...</span>
                                    </div>
                                    <p className="text-warning mt-2">กำลังโหลด...</p>
                                </div> :
                                props.lists.map((item, i) => {
                                    return (
                                        <div className="card mb-2 p-3 text-nowrap" key={i}>
                                            <div className="row fw-bold">
                                                <h5 className="col-auto mb-0">{item.title}</h5>
                                                <h5 className="col text-end mb-0">{Format.price(item.total)}</h5>
                                            </div>
                                            <div className="row text-muted fs-7">
                                                <div className="col-auto">{item.type}</div>
                                                <div className="col text-end">{item.unit}</div>
                                            </div>
                                        </div>
                                    )
                                })}
                        </Fragment> : null
                    }
                </div>
            </div>
        </div>
    )
}

PieTwinChart.defaultProps = {
    title: "title",
    titleList: "titleList",
    backgroundColor: ["#FFC107", "#212529", "#82868B"],
    labels: [],
    data: [],
    lists: [],
    width: 100,
    height: 100,
    hideChart: false,
    hideType: false,
    hideList: false,
    unit: "บาท",
    loading: false
}

export default PieTwinChart